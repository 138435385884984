import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { OSSLT_ASSESSMENT_MODULES, OSSLT_ASSESSMENT_MODULES_FR } from '../../ui-student/osslt-page-switcher/data/dummy-session-data';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy, HostListener, OnChanges, SimpleChanges, ChangeDetectorRef, ViewEncapsulation, Renderer2 } from '@angular/core';
import {  LangService } from '../../core/lang.service';
import { Subscription } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { ISectionDef, ITestDef, ISectionMeta, SectionDrawingCtx } from '../sample-questions/data/sections';
import { reject } from 'q';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { checkElementIsEntry, getElementChildren, IQuestionConfig, IQuestionRun } from '../../ui-item-maker/item-set-editor/models';
import { ElementType,IContentElement, IContentElementCanvasPage, IContentElementIframe, IContentElementTextLink, IContentElementCanvas, IEntryStateMcq, IScoredResponse, getElementWeight, IAssetImpressionConfig, ReviewPageFilters, ReviewPageFilterMode } from '../models';
import {ChatService} from '../../chat/chat.service';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { TestFormConstructionMethod, IAssessmentFrameworkDetail, ReportOptions, ISectionItem } from '../../ui-item-maker/item-set-editor/models/assessment-framework';
import { DrawDisplayMode } from '../element-render-drawing/constants';
import { IPanelModuleDef } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { randArrEntry } from '../../ui-testadmin/demo-data.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { HyperlinkService, ILinkRequest } from '../hyperlink.service'
import { CanvasService, PageSwitch } from '../canvas.service'
import { UrlLoaderService } from '../url-loader.service';
import { DrawingLogService } from '../drawing-log.service';
import { StudentG9ConnectionService } from '../../ui-student/student-g9-connection.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import { getQuestionTitles, getQuestionTitleFromMap } from './util/question-titles';
import { QuestionScore } from '../../ui-item-maker/view-g9-sample/view-g9-sample.component';
import {identifyQuestionResponseEntries} from '../../ui-item-maker/item-set-editor/models/expected-answer'
import { DataGuardService } from '../../core/data-guard.service';
import { pageNodeDef } from './util/drawings';
import { KNOWN_TEST_RUNNER_TAGS } from './tags/known-tags';
import { ZoomService } from '../zoom.service';
import { CALC } from '../widget-calculator/widget-calculator.component';
import { isOldFirefoxBrowser } from '../services/util'
import { AssessmentType, assessmentInfo } from '../../bc-assessments/bc-assessments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { videoPlaybackService } from '../video-playback.service';
import { QuestionRunnerComponent } from '../question-runner/question-runner.component';
import { Keystroke, StudentSecurityService } from '../student-security.service';

export interface ITestState {
  languageCode: string;
  furthestSectionIndex: number;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  currentReadingPassageId?: number;
  currentModuleId?: number;
  readSelItemId?: number;
  questionStates: any;
  isSubmitted?: boolean;
  notes?: string;
  currentSectionsAllowedIndex?: number;
}

interface ItemScore {
  //  itemScore: { 
  //   [entryId: number]: number;
  // };
  correctItemScore:number    // total of individual item's score array (itemScore)
  totalItemWeight: number;   //  total weight of an individual item
}

interface FinalReportStats {
  itemScore: { 
    [qId: number]: ItemScore;
  } 
  numSRQuestions : number;
  numCRQuestions : number;
  numCorrectSRQuestions : number;
  correctSRScore : number;
  totalSRScore : number;
  totalCRScore : number;
}

interface ReaderInfo {
  readerId?: string,
  canvasId?: string,
  itemLabel?: string
}

interface SaveQuestionPayload {
  test_question_id: number,
  test_question_version_id: number,
  question_index: number,
  question_caption: string,
  section_index: number,
  module_id: number,
  response_raw: string,
  response: string,
  disableQuestionResponseShrinkageDetection: boolean
}

enum ReaderTextMode {
  CLOSED = 'CLOSED',
  HALF = 'HALF',
  FULL = 'FULL',
}

enum PageMode {
  REVIEW_QUESTIONS = 'REVIEW_QUESTIONS',
  RESULTS_INTRO = 'RESULTS_INTRO',
  RESULTS_SUMMARY = 'RESULTS_SUMMARY',
  RESULTS_INSTRUCTIONS = 'RESULTS_INSTRUCTIONS',
  TEST_RUNNER = 'TEST_RUNNER'
}
const DEFAULT_NOTEPAD_ID = 'NOTEPAD'
const DEFAULT_LINEREADER_WIDTH = 1000;
const DEFAULT_NOTES_TEST_QUESTION_ID = -2 // currently hard coded #TODO : later allow to be configured in the framework

export interface ICustomConfirmTestDialogData {
  text: string;
  confirmMsg: string;
  cancelMsg: string;
}

export  const parseQuestionSaveError = (msg: string, slug?:string) => {
  // if slug is provided override the default
  switch (msg) {
    case 'NOT_BOOKED_APPL': return 'msg_no_longer_booked_err';
    case 'ATTEMPT_CLOSED': return slug ?? 'msg_attempt_closed_err';
    case 'SESSION_CLOSED': return 'msg_session_closed_err';
    case 'SESSION_ENDED': return 'msg_session_ended';
    case 'MARKED_NO_ID': return 'msg_marked_no_id';
    case 'MARKED_ABSENT': return 'msg_marked_absent';
    case 'ATTEMPT_PAUSED': return 'Your attempt has been paused for this session';
    // case 'NOT_VERIFIED': return 'msg_not_verified';
    case 'NOT_VERIFIED': return 'msg_paused_err';
    case 'VERIFY_QUESTION_SUBMIT':  return 'msg_question_save_shrinkage_detected_BC'
  }
}

export const handleSubmitTestErr = (e, loginGuard: LoginGuardService, lang: LangService, slugs?: {slugMsg?: string, slugErr?: string}) => {
  let slugMsg = 'msg_may_not_submit_test';
  let slugErr = undefined;

  if(slugs){
    if('slugMsg' in slugs) slugMsg = slugs.slugMsg;
    if('slugErr' in slugs) slugErr = slugs.slugErr;
  }
  
  loginGuard.confirmationReqActivate({
    caption: lang.tra(slugMsg) + ' ' + lang.tra(parseQuestionSaveError(e.message, slugErr))
  });
}

@Component({
  selector: 'test-runner',
  templateUrl: './test-runner.component.html',
  styleUrls: ['./test-runner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestRunnerComponent implements OnInit,AfterViewInit, OnDestroy, OnChanges {

  @Input() asmtFmrk: IAssessmentFrameworkDetail;
  @Input() asmtCaption: string;
  @Input() attemptKey: string;
  @Input() autoScrollOnSelect: boolean = false;
  @Input() btnReviewSubmit: string = 'btn_review_submit'; // please deprecate
  @Input() checkChat: () => Promise<any>;
  @Input() checkTime: () => Promise<any>;
  @Input() currentSession: Object;
  @Input() currentTestDesign: ITestDef;
  @Input() customConfirmTestDialogData: ICustomConfirmTestDialogData;
  @Input() dateTimeStart: moment.Moment;
  @Input() documentItems: {itemId:number, caption:string}[];
  @Input() exitResults: () => Promise<any>;
  @Input() forceQuestionsSkippable: boolean = false;
  @Input() frameWorkTags: {slug:string}[];
  @Input() goToDashboard: () => any;
  @Input() helpPageItem: number;
  @Input() instit_group_id: number;
  @Input() isChatEnabled: boolean;
  @Input() isExitEnabled: boolean;
  @Input() isHelpEnabled: boolean = true;
  @Input() isIssueReportingEnabled: boolean;
  @Input() isNavFooterDisabled: boolean;
  @Input() isOsslt:boolean; // deprecated
  @Input() isOssltTools:boolean; // deprecated
  @Input() isPrintMode: boolean;
  @Input() isSectionControlsEnabled: boolean = true;
  @Input() isShowingResults:boolean = false; 
  @Input() isShowQuestionLabel: boolean;
  @Input() isText2SpeechEnabled: boolean = true;
  @Input() isTimeEnabled: boolean = true;
  @Input() isToolExploration: boolean; // is this a duplicate of isOssltTools ? please deprecate
  @Input() moduleIdInit: number;
  @Input() noTestConfirmationRequired: boolean = false;
  @Input() questionIndexInit: number;
  @Input() questionSrcDb: Map<number, IQuestionRun>;
  @Input() questionStates: {[key: string]: any};
  @Input() regularTimeRemaining: string;
  @Input() rubricDownloadLink:string;
  @Input() saveQuestion: (data: any) => Promise<any>;
  @Input() logAssetView: (data: any) => Promise<any>;
  @Input() logItemView: (data: any) => Promise<any>;
  @Input() logQuestionTimeSpent: (data: any) => Promise<any>;
  @Input() getQuestionTimeSpent: () => Promise<any[]>;
  @Input() sectionIndexInit: number;
  @Input() sectionsAllowed?: number[];
  @Input() sessions: Array<Object> = [];
  @Input() showAnswers: () => Promise<any>;
  @Input() studentG9Connection: StudentG9ConnectionService
  @Input() submitTest: () => Promise<any>;
  @Input() testAttemptId: number
  @Input() testFormId: number;
  @Input() testFormType: string;
  @Input() testLang: string;
  @Input() testSessionId: number;
  @Input() testTakerName: string;
  @Input() testTakerFirstName: string;
  @Input() testTakerLastName: string;
  @Input() testTakerPEN: number;
  @Input() defaultZoomInit: number;
  @Input() assessmentInfo?: assessmentInfo;
  @Input() testAttemptHash? : string;
  @Input() isAccomm? : number;
  @Input() isTTS? : number;

  @Output() backToMap = new EventEmitter()
  @Output() backToMenu = new EventEmitter()
  @Output() endSection = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() questionTitles = new EventEmitter();
  @Output() onEditItem = new EventEmitter();

  @ViewChild('questionDisplay', { static: false }) questionDisplay: ElementRef<HTMLDivElement>;
  @ViewChild('rubricLink') rubricLinkRef : ElementRef;
  @ViewChild('topBar', { static: false }) topBar: ElementRef<HTMLDivElement>;
  @ViewChild('lineReader') lineReader: ElementRef<HTMLDivElement>
  @ViewChild('scrollIndicator') scrollIndicator: ElementRef;
  @ViewChild('questionRunner') questionRunner: QuestionRunnerComponent;
    
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.updateScreenShrinkFactor();
    this.updateDragZoomCorrection();
  }
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mousePosition = { x: event.clientX, y: event.clientY };
    if(this.isResizeLineReaderWidth === true) this._resizeLineReaderWidth();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onGlobalClick(event: MouseEvent, targetElement: HTMLElement){
    if (!targetElement) return;
    const el = document.getElementById('section-navigation-dropdown-trigger');
    const clickedInside = el && (el.contains(targetElement) || el === targetElement);
    if (!clickedInside) this.isSectionNavDropdownOpen = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    $event.preventDefault();  
    $event.returnValue = true; // for legacy cases
    this._saveQuestion(true, true);
  }

  constructor(
    public lang: LangService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    public auth: AuthService,
    public textToSpeech: TextToSpeechService,
    public chatService: ChatService,
    public whitelabel: WhitelabelService,
    private safeUrl: UrlLoaderService,
    private drawLog: DrawingLogService,
    private hyperLinkService: HyperlinkService,
    private styleProfile: StyleprofileService,
    private changeDetector: ChangeDetectorRef,
    private dataGuard: DataGuardService,
    private canvasService: CanvasService,
    private zoom: ZoomService,
    private renderer: Renderer2,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private playback: videoPlaybackService,
    private studentSecurity: StudentSecurityService,
  ) { 
  }

  showSolution;
  defaultZoomLevel = 1.5;
  minZoomLevel = 0.5;
  maxZoomLevel = 3;
  zoomIncrement = 0.25;
  testState: ITestState;
  routeSub: Subscription;
  isSyncing: boolean;
  isShowingSectionInfo: boolean;
  isFormulasToggledOn: boolean;
  isCalcToggledOn: boolean;
  isHighContrast: boolean;
  isHelpOverlay: boolean;
  sectionTimeStarted: number;
  sectionTimeRemaining;
  helpScreenLayout:any;
  ticker;
  questionResaveInterval;
  currentReadSelection:string;
  currentBookmark:string;
  itemLabel: string;
  readerInfo: ReaderInfo[];
  isSavingResponse: boolean;
  isQuietlySavingResponse: boolean;
  currentModal: any;
  isShowingReadingSelections: boolean;
  isShowingTime;
  testStartTime: number;
  isShowingChat;
  isShowingReport;
  isTestNavOnTop:boolean = false
  issueReportMessage = new FormControl();
  isLineReaderActive: boolean;
  mousePosition: {x: number, y:number};
  lineReaderPosition: {left:number, top: number};
  isResizeLineReaderWidth: boolean = false;
  lineReaderWidth:number = DEFAULT_LINEREADER_WIDTH;
  isTestNavExpanded: boolean = true;
  isToolbarExpanded: boolean;
  activeReadingSelection: IQuestionConfig;
  // resultQs: {qId: number, sIndex: number, qIndex: number}[] = [];
  resultQs: number[] = [];
  documentViews: IMenuTabConfig<number>[];
  selectedDocumentId:number;
  drawingCtx;
  clearAllDrawings:boolean
  drawingPageIndexTracker = new Map();
  PageMode = PageMode;
  questionScores: Map<number, QuestionScore> = new Map();
  questionPScores: Map<number, number> = new Map();
  screenWidth;
  isShowFormulaSheet = false;
  isShowDocuments = false;
  showEraser = false;
  showLine = false;
  showHighlight = false;
  transValue
  drawMode = '';
  currIEZoom = 100
  isShowOverlay = false;
  isShowDrawing = false;
  isNotepadEnabled = false;
  isZoomIn = false;
  isZoomOut = false;
  timeSpentMap: Map<number, number>;
  scrollIndicationStatus:boolean = false

  //filters for review-page
  reviewPageFilters:ReviewPageFilters;
  reviewPageFilterMode = ReviewPageFilterMode;
  isOnReviewQuestionsPage: boolean;  
  totalFlagged: number;
  totalUnAnswered: number;
  isReviewIncludedInNavigation: boolean
  isReviewQuestionUnlockedforNav: boolean;

  pageMode : PageMode;
  pageModeFlow = [
    {slug: PageMode.RESULTS_INTRO, caption: 'tr_results_intro_title'}, 
    {slug: PageMode.RESULTS_SUMMARY, caption: 'tr_results_summary_title', isDetail:true}, 
    {slug: PageMode.RESULTS_INSTRUCTIONS, caption: 'tr_results_instr_title', isDetail:true}, 
    {slug: PageMode.REVIEW_QUESTIONS, caption: 'review_questions_page_title'}, 
    {slug: PageMode.TEST_RUNNER, caption: undefined}
  ];
  pageModeIndex : number;
  iframeUrl
  element:IContentElementIframe = {
     elementType:ElementType.IFRAME,
     url:''
  }
  count = 0; //for progressbar
  totalFilledBySection = {}; //for progressBar
  // public numSRQuestions : number;
  // public numCorrectSRQuestions : number;
  // public numCRQuestions : number;
  // public correctSRScore : number;
  // public totalSRScore : number;
  // public totalCRScore : number;
  finalReportStats: FinalReportStats;
  frameWorkTagsRef = new Map();
  isInitialized:boolean;
  helpPageState = {};
  KNOWN_TEST_RUNNER_TAGS = KNOWN_TEST_RUNNER_TAGS;
  reportOptions = ReportOptions
  questionsForNavigation = [];
  isSectionNavDropdownOpen:boolean = false;
  isSectionNavDropdownEnabled:boolean = false;
  
  ngOnInit() {
    this.initZoom();
    this.zoom.updateScreenShrink(1);
    const root = document.getElementsByTagName("html")[0];
    root.style.overflowX="auto";

    try {
      this.initScreenPresenceDetection();
    } catch(e) {
      console.log('initScreenPresenceDetection failed', e);
    }
    try {
      this.studentSecurity.init();
    } catch (e) {
      console.warn('Could not initialize security.');
    }

    this.initToolbarOption()
    this.initTestDef();
    this.initTestState();
    this.initNavigationDropdown();
    this.initTags();
    if (this.isChatEnabled){
      this.initChatPage();
    }
    if (this.isHelpEnabled){
      this.initHelpPage();
    }
    this.initDocuments();
    this.initHyperlinkService();
    this.initCanvasService();
    this.initTicker();
    this.setDefaultZwibblerContext();
    this.initFirstQuestion();
    this.setResultsIntroTitleSlug();
    this.selectPageMode(this.pageModeFlow.length - 1);
    this.onResize();
    this.checkForImmediateResults().then(()=> {
      this.isInitialized = true;
    })
    
    if(this.isReviewQuestionsEnabled()) this.initReviewPageFilters();
    // this.testState.currentSectionIndex = 1;
    // this.selectQuestion(1,1)
    // setTimeout(()=> {
    //   console.log('getCurrentSectionPreambleContent', this.getCurrentSectionPreambleContent())
    // }, 4000)

    if(this.checkIsOsslt()){
      if((this.currentSession !== this.sessions[this.count])){
        this.count++ // increase count; to start check on next Session
      } 
      if( JSON.parse(sessionStorage.getItem("totalFilledBySection")) && (this.currentSession === this.sessions[this.count])){
        this.totalFilledBySection = JSON.parse(sessionStorage.getItem("totalFilledBySection"));
      } 
    }

    if (this.textToSpeech.isHiContrast){
      console.log('Test runner initialized, HC is on, turning off...')
      this.textToSpeech.hi_contrast_off();
    }

    this.initStudentSecurity();
  }

  initStudentSecurity() {
    const item_id = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    this.studentSecurity.initIds(this.auth.getUid(), this.testSessionId, this.testAttemptId, item_id );

  }

  checkIsOsslt(){
    return this.isOsslt || this.checkTag('OSSLT'); // non-tag approach is deprecated
  }
  checkIsOssltTools(){
    return this.isOssltTools || this.checkTag('OSSLT_TOOLS'); // non-tag approach is deprecated
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.isInitialized && changes.isShowingResults && this.isShowingResults) {
      this.showResults();
    }
    if (changes.defaultZoomInit){
      this.initZoom();
    }
  }

  // todo: these listeners should be implemented with Angular listerenets
  // see:   @HostListener('window:resize', ['$event'])
  // see:   @HostListener('document:click', ['$event', '$event.target'])
  initScreenPresenceDetection(){
    // detecting browser tab change
    window.onblur = () => {
      this.logStudentActivity('student-asmt-activity/tab-screen-change')
    }
    // Detecting fullscreen change
    document.addEventListener('fullscreenchange', this.fullScreenListener);
  }
  fullScreenListener = (event) => {
    if(!document.fullscreenElement) {     
      this.logStudentActivity('student-asmt-activity/full-screen-change')
    }
  }
  async logStudentActivity(slug){
    const item_id = this.getCurrentQuestions()[this.getCurrentQuestionIndex()]
    await this.auth.apiCreate(this.routes.LOG, { 
      slug, 
      uid:this.auth.getUid(), 
      ts_id:this.testSessionId, 
      item_id,
    })
  }
  clearPresenceDetection(){
    // not needed if we are using  @HostListener
    window.onblur = () => {} ; // todo:  dangerous, we need a better way of tracking window blur
    document.removeEventListener('fullscreenchange', this.fullScreenListener, true);
  }

  
  initZoom(){
    console.log('defaultZoomInit', this.defaultZoomInit)
    this.zoom.update(this.defaultZoomLevel * (this.defaultZoomInit || 1));
  }

  disableCalcSD = false;
  disableCalcRoot = false;
  disableCalcDecimal = false;
  disableCalcFraction = false;
  enableScientificCalc:boolean = false;
  disableCalcFactorial = false;
  disableCalcMOD = false;
  disableCalcABS = false;
  disableCalcln = false;
  disableCalcLOGY = false;
  disableCalcLOG10 = false;
  disableCalcE = false;
  disableCalcEPOW = false;
  disableCalcPI = false;
  disableCalcPOW = false
  disableCalcNTHROOT = false;
  disableCalcONEOVERX = false;
  disableCalcEXP = false;

  initTags(){
    // console.log('frameWorkTags', this.frameWorkTags);
    if (this.frameWorkTags){
      this.frameWorkTags.forEach(tag => {
        const key = (''+tag.slug).trim();
        this.frameWorkTagsRef.set(key, true);
        if (tag.slug == "CALC_ENABLE_SCIENTIFIC") {
          this.enableScientificCalc = true
        }
        if (tag.slug=="CALC_DISABLE_SD") {
          this.disableCalcSD = true
        } else if (tag.slug=="CALC_DISABLE_ROOT") {
          this.disableCalcRoot = true
        } else if (tag.slug=="CALC_DISABLE_DECIMAL") {
          this.disableCalcDecimal = true
        } else if (tag.slug=="CALC_DISABLE_FRAC") {
          this.disableCalcFraction = true
        } else if (tag.slug=="CALC_DISABLE_FACTORIAL") {
          this.disableCalcFactorial = true
        } else if (tag.slug=="CALC_DISABLE_MOD") {
          this.disableCalcMOD = true
        } else if (tag.slug=="CALC_DISABLE_ABS") {
          this.disableCalcABS = true
        } else if (tag.slug=="CALC_DISABLE_LN") {
          this.disableCalcln = true
        } else if (tag.slug=="CALC_DISABLE_LOGY") {
          this.disableCalcLOGY = true
        } else if (tag.slug=="CALC_DISABLE_LOG10") {
          this.disableCalcLOG10 = true
        } else if (tag.slug=="CALC_DISABLE_E") {
          this.disableCalcE = true
        } else if (tag.slug=="CALC_DISABLE_EPOW") {
          this.disableCalcEPOW = true
        } else if (tag.slug=="CALC_DISABLE_PI") {
          this.disableCalcPI = true
        } else if (tag.slug=="CALC_DISABLE_POW") {
          this.disableCalcPOW = true
        } else if (tag.slug=="CALC_DISABLE_NTHROOT") {
          this.disableCalcNTHROOT = true
        } else if (tag.slug=="CALC_DISABLE_ONEOVERX") {
          this.disableCalcONEOVERX = true
        } else if (tag.slug=="CALC_DISABLE_EXP") {
          this.disableCalcEXP = true
        } 
      })
      this.resetFlag()
    }
    this.whitelabel.setIsBCGRAD(this.checkTag('BC_GRAD'));
    this.playback.isUniversalPlaybackSpeedDisabled = this.checkTag('DISABLE_UNIVERSAL_PLAYBACK');
  }

  initToolbarOption(){
    let defaults = {
      zoomIn : true,
      zoomOut: true,
      lineReader: true,
      hiContrast: true,
      toggleEditor: true,
      highlighter: true,
      eraser: true,
      notepad: true,
    }
    
    if(!this.asmtFmrk.toolbarOptions) this.asmtFmrk.toolbarOptions = defaults
  }

  initReviewPageFilters(){
    if(!this.reviewPageFilters) {
      this.reviewPageFilters = {
        viewAll : true, //Initially true
        unAnswered: false,
        flagged : false
      }
    }

    if(this.testState.currentSectionIndex >= this.asmtFmrk.partitions.length && !this.isShowingResults){
      this.reviewQuestionPage();
    }
  }

  checkTag(tag:string){
    return this.frameWorkTagsRef.get(tag);
  }

  resultStates:{[key: string]: any} = {};
  resultQuestDefs: Map<number, IQuestionRun> = new Map();
  showResults(){
    this.selectPageMode(0); // Select first page mode
    this.resultQs = [];
    for(let section of this.testRunnerSections) {
      if(!section.disableScoring){
        for(let questionId of section.questions) {
          if(!this.questionSrcDb.get(questionId).isReadingSelectionPage) {
            // this.resultQs.push({qId: questionId, sIndex, qIndex});
            this.resultQs.push(questionId);
            this.resultStates[questionId] = JSON.parse(JSON.stringify(this.getQuestionState(questionId)))
            const def:IQuestionConfig = JSON.parse(JSON.stringify(this.getQuestionDef(questionId)))
            this.resultQuestDefs.set(questionId, def)
          }
        }
      }
    }
  }

  getResultState(question:number) {
    return this.resultStates[question]
  }

  checkForImmediateResults(){
    return Promise.all(
      this.testRunnerSections.map((section, sectionIndex) => {
        return this.ensureSectionPathFill(sectionIndex, false, true)
      })
    )
    .then(() => {
      if(this.isShowingResults && this.isFlushNavigation()) {
        this.showResults();
        this.scoreAllQuestions();
      }
    })
  }

  initDocuments(){
    this.documentViews = [];
    if (this.documentItems){
      Promise.all(
        this.documentItems.map(document => {
          this.documentViews.push({
            id: document.itemId,
            caption: document.caption,
          })
          return this.loadDocument(document.itemId);
        })
      )
      .then(()=>{
        if (this.documentItems){
          const firstDocument = this.documentItems[0];
          if (firstDocument){
            this.selectDocumentView(firstDocument.itemId)
          }
        }
      });
    }
  }

  initCanvasService() {
    this.canvasService.canvasPageNumChanged.subscribe(this.canvasPageChanged)
  }
  
  linkRequestSub: Subscription;
  requestForLinkUpdateSub: Subscription;
  canvasBookmarkChangedSub: Subscription;
  initHyperlinkService(){
    this.isShowingReadingSelections = false;
    this.linkRequestSub = this.hyperLinkService.linkRequest.subscribe(this.onLinkRequest);
    this.requestForLinkUpdateSub = this.hyperLinkService.requestForLinkUpdate.subscribe(this.onLinkRequestFromCanvas);
    this.canvasBookmarkChangedSub = this.hyperLinkService.canvasBookmarkChanged.subscribe(this.setLinkInfo);
    this.hyperLinkService.linkRequest.next({
      readerElementId: undefined,
      readerId: undefined
    })
  }

  clearHyperlinkService(){
    this.linkRequestSub.unsubscribe();
    this.requestForLinkUpdateSub.unsubscribe();
    this.canvasBookmarkChangedSub.unsubscribe();
  }

  canvasPageChanged = (data:PageSwitch) => {
    const readerId = data.canvasId
    const pageNum = data.pageNum
    const leftId = this.getActiveQuestionId()
    const rightId = this.testState.readSelItemId
    if (this.isQuestionCanvas(leftId)) {
      const canvasObj = <IContentElementCanvas>this.getActiveQuestionContent().content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.leftPageId = pageNum;
      }
    }
    if (this.isQuestionCanvas(rightId)) {
      const canvasObj = <IContentElementCanvas>this.getQuestionDef(rightId).content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.rightPageId = pageNum;
      }
    }
  }

  initFirstQuestion(){
    this.scrollToQuestion()
    this.lastFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    this.getIframeURL()
    this.initText2Speech();
    this.setActiveReadingSelection();
    if (!this.getCurrentSectionPreambleContent()){
      this.markActiveQuestionAsStarted();
    }
    this.getReadingSelectionCanvases();
    this.reinitReadingSelection()
    this._logItemView();
    this._logAssetView();
    // console.log ('initFirstQuestion', this.isShowingLeft(), this.isShowingRight())
  }

  initText2Speech(){
    if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT')) || this.whitelabel.getSiteFlag('IS_NWT')){
      if ((this.frameWorkTagsRef.get('ENABLE_LISTEN_TOOL') || this.activatedRoute.snapshot.queryParamMap.get('isTTS')) && !this.frameWorkTagsRef.get('DISABLE_LISTEN_TOOL')) {
        this.isText2SpeechEnabled = true;
      } else {
        this.isText2SpeechEnabled = false;  
      }
    }
  }

  isProgressBarEnabled(){
    return !(this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))
  }

  isInfoIconEnabled(){
    return !(this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))
  }

  isFlushNavigation(){
    return (this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))
  }

  isUsingTemporaryNotes(){
    return this.checkIsOsslt() || (this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'));
  }

  setResultsIntroTitleSlug = () => {
    if(this.asmtFmrk.isOrale){
      for(let i = 0; i < this.pageModeFlow.length; i++){
        if(this.pageModeFlow[i].slug === PageMode.RESULTS_INTRO){
          this.pageModeFlow[i].caption = 'tr_results_intro_title_orale'
          return;
        }
      }
    }
  }

  isCustomNavbarResultsIntroPageTitle(pageMode){
    return pageMode === PageMode.RESULTS_INTRO && (this.asmtFmrk.customNavbarResultIntroPageTitleEN || this.asmtFmrk.customNavbarResultIntroPageTitleFR)
  }

  getCustomNavbarResultsIntroPageTitle = () => {
    return this.lang.c() === 'fr' ? this.asmtFmrk.customNavbarResultIntroPageTitleFR : this.asmtFmrk.customNavbarResultIntroPageTitleEN;
  }

  getSectionSlug(){
    if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
      return 'Part';
    }
    else{
      return 'title_stage'
    }
  }

  getSectionId = (sectionIdx) => this.asmtFmrk.partitions[sectionIdx].id;

  getFlagSlug(isUnflag?:boolean){
    if (!isUnflag){
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        return 'btn_flag_question_bc'
      }
      else if (this.checkIsOsslt() || this.checkIsOssltTools()){
        return 'btn_flag_question'
      }
      else{
        return 'btn_flag_question_g9'
      }
    }
    else{
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        return 'btn_unflag_question_bc'
      }
      else if (this.checkIsOsslt() || this.checkIsOssltTools()){
        return 'btn_unflag_question'
      }
      else{
        return 'btn_unflag_question_g9'
      }
    }
  }

  getOpenReadSelSlug(isOpen:boolean){
    
    if (isOpen){
      if (this.checkTag("BC_GRAD")){
        return 'btn_hide_read_sel_LTP'
      }
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        return 'btn_hide_read_sel_bc';
      }
      return 'btn_hide_read_sel';
    }
    if (this.checkTag("BC_GRAD")){
      return 'btn_view_read_sel_LTP'
    }
    if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
      return 'btn_view_read_sel_bc';
    }
    return 'btn_view_read_sel';

    
  }

  appendNum(num1, num2){
    return parseInt(num1.toString() + num2.toString())
  }

  section2Drawing = new Map()
  section2PIndex = new Map()
  setDefaultZwibblerContext() {
    const section = this.testState.currentSectionIndex
    let defaultStr = this.section2Drawing.get(section)
    let drawingPageIndexTracker = this.section2PIndex.get(section)
    console.log(defaultStr, drawingPageIndexTracker)
    if (!defaultStr || !drawingPageIndexTracker) {
      // console.log("HELLLOOOOOOOO!!!!!!!!!")
      defaultStr = "zwibbler3."
      const pages = [];
      const obj = {
        id: 0,
        type: "BaseNode"
      }
      drawingPageIndexTracker = new Map()
      const addEntry2DrawingPages = (question, questDef)=>{
        if (this.isQuestionCanvas(question)) {
          const canvasContent = <IContentElementCanvas> questDef.content[0]
          canvasContent.pages.forEach((page:IContentElementCanvasPage, pindex)=>{
            let thisId = this.appendNum(question, pindex)
            //if (pindex==0) thisId = question
            drawingPageIndexTracker.set(thisId, index)
            pages.push(pageNodeDef(nextID))
            index++
            nextID++
          })
        } else {
          pages.push(pageNodeDef(nextID));
          drawingPageIndexTracker.set(question, index)
          nextID++;
          index++
        }
      }

      

      pages.push(JSON.stringify(obj));
      const questions = this.getCurrentQuestions();
      let nextID = 1000000000
      drawingPageIndexTracker.clear();

      let index = 0;
      questions.forEach((question, index)=>{
        const questDef = <IQuestionConfig>this.getQuestionDef(question)
        addEntry2DrawingPages(question, questDef)
      })
      questions.forEach((question)=>{
        const readingSelections = this.getQuestionDef(question).readSelections;
        if (readingSelections) {
          readingSelections.forEach((readSel)=>{
            for (const [key, value] of this.questionSrcDb.entries() ) {
              const questDef = <IQuestionConfig>value
              if (questDef.label == readSel) {
                if (!drawingPageIndexTracker.has(key)) {
                  addEntry2DrawingPages(key, questDef)
                }
              }
            }
          })
        }
      }) 
      // console.log("Default", JSON.parse("["+pages.toString()+"]"))
      defaultStr += "["+pages.toString()+"]";
    }
    this.drawingCtx = defaultStr;
    this.drawingPageIndexTracker = drawingPageIndexTracker
    this.section2Drawing.set(section, this.drawingCtx)
    this.section2PIndex.set(section, this.drawingPageIndexTracker)
    this.resetFlag()    
  }

  getZoomLevel() {
    return this.zoom.getZoom();
  }

  getScreenShrink() {
    return this.zoom.getScreenShrink();
  }

  getScreenShrinkZoom() {
    return this.zoom.getScreenShrinkZoom();
  }
  getZoomValue(){
    // const ua = navigator.userAgent;
    // if (ua.indexOf("iPad")!=-1 || ua.indexOf("iPhone")!=-1) {
    //   if (this.getZoomLevel() > this.defaultZoomLevel) {
    //     this.zoom.update(this.defaultZoomLevel);
    //   }
    // }
    return this.getScreenShrinkZoom();
  }
  getScaleValue() {
    return `scale(${this.getZoomValue()})`;
  }

  saveDrawing(drawingContext:SectionDrawingCtx) {
    const drawingSection = drawingContext.section;
    const drawing = drawingContext.ctx;
    if (this.getCurrentSectionIndex() != drawingSection) {
      console.log("different section")
      return;
    }

    console.log("same section");
    if (!this.drawingCtx) {
      this.drawingCtx = drawing
      return;
    }

    const startIndex = drawing.indexOf('[');
    const objStr = drawing.substr(startIndex);
    const obj = JSON.parse(objStr);
    const oldObjStr = this.drawingCtx.substr(this.drawingCtx.indexOf('['));
    const oldObj = JSON.parse(oldObjStr)
    console.log("old obj", oldObj)
    console.log("obj", obj)
    obj.forEach(element => {
      let found = false;
      oldObj.forEach((existing, index)=>{
        if (existing["id"]==element["id"]) {
          found = true;
        }
      })
      if (found) return;
      let parentIndex = -1;
      let placed = false;
      oldObj.forEach((existing, index)=>{
        if (placed==true) return;
        if (existing["id"]==element["parent"] && parentIndex == -1) {
          parentIndex = index;
        } 
        
        if (parentIndex !=-1) {
          if (oldObj.length - 1 >index && oldObj[index+1] && oldObj[index+1]["type"]!="PageNode" ) {
            return;
          }
          let nextIndex = index+1;
          if (oldObj.length - 1 >index) {
            nextIndex--;
          }
          if (index<oldObj.length-1) oldObj.splice(index+1, 0, JSON.parse(JSON.stringify(element)))
          else oldObj.push(JSON.parse(JSON.stringify(element)))
          placed = true;
        }
      })
    });
    this.drawingCtx = "zwibbler3."+JSON.stringify(oldObj)
    // console.log("Saved", JSON.parse(this.drawingCtx.substr(this.drawingCtx.indexOf('[')))) 
  }

  loadDrawing() {
    return this.drawingCtx
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
    setTimeout(() => {
      this.scrollToQuestion()
    }, 100)
  }

  onLinkRequest = (data:ILinkRequest) => {
    if (!data.readerId && !data.readerElementId && !data.itemLabel) {

      if(this.getReadingSelectionState().__meta.startTime && this.getCurrentDateTime() !== this.getReadingSelectionState().__meta.startTime && this.getActiveReadingSelectionId()){
        // log time
        this._logQuestionTimeSpent(true)
      }      
      this.closePassage()
    } 
    else {
      this.currentReadSelection = data.readerId;
      this.currentBookmark = data.readerElementId;
      this.itemLabel = data.itemLabel;
      if (!data.itemLabel){
        this.testState.currentReadingPassageId = this.getCurrentReadingPassageId();
      }
      else this.testState.currentReadingPassageId = this.getCurrentReadingPassageId();
      if (this.currentReadSelDisplayMode === ReaderTextMode.CLOSED){
        this.readSelViewHalf();
      }
      
      this.setActiveReadingSelection();
      // const readSelState = ;
      this.getReadingSelectionState().__meta.startTime = this.getCurrentDateTime();
      
      this.hyperLinkService.linkRequestSecond.next({
        readerElementId: this.currentBookmark,
        readerId: this.currentReadSelection,
        itemLabel: this.itemLabel,
        bookmarkId: data.bookmarkId
      });
      this.onResize();
    }
    
  }

  onLinkRequestFromCanvas = (data:ILinkRequest) => {
    if (this.currentBookmark != data.readerElementId || this.currentReadSelection != data.readerId) {
      this.hyperLinkService.linkRequestSecond.next({
        readerElementId: this.currentBookmark,
        readerId: this.currentReadSelection,
      })
      this.onResize();
    }
  }

  setLinkInfo = (data:ILinkRequest) => {
    // console.log("Test runner unset Bookmark")
    this.currentReadSelection = data.readerId;
    this.currentBookmark = data.readerElementId;
    this.leftPageId = 0;
    this.rightPageId = 0;
    this.itemLabel = undefined;
  }


  getCurrentReadingPassageId() {
    const questions = this.getCurrentQuestions();
    let readingPassageQuestionID = undefined;
    questions.forEach((questID)=>{
      const question = this.getQuestionDef(questID);
      const content = question.content;
      content.forEach((element)=>{
        if (element.elementType == ElementType.CANVAS ) {
          if (element["readerId"] == this.currentReadSelection) {
            readingPassageQuestionID = questID;
          }
        }
      })
    })
    return readingPassageQuestionID;
  }

  isQuestionCanvas(id) {
    const questDef = this.getQuestionDef(id);
    if (questDef && questDef.content && questDef.content.length>0) {
      if (questDef.content[0].elementType == ElementType.CANVAS && questDef.content[0]["pages"]) {
        return true;
      }
    }
    return false;
  }

  leftPageId=0;
  rightPageId=0;
  getCurrentLeftItemId() {
    if (this.isQuestionCanvas(this.getActiveQuestionId())) {
      return this.appendNum(this.getActiveQuestionId(), this.leftPageId)
    } else {
      return this.getActiveQuestionId()
    }
  }

  getCurrentRightItemId() {
    if (this.isQuestionCanvas(this.testState.readSelItemId)) {
      return this.appendNum(this.testState.readSelItemId, this.rightPageId)
    } else {
      return this.testState.readSelItemId;
    }
  }

  convertReadSelToTextLink(readerId) {
    let textLinkEl = {};
    if (readerId.canvasId) {
      textLinkEl = {
        readerId: readerId.canvasId,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        readingSelectionCaption: readerId.readingSelectionCaption,
        itemLabel: undefined
      } 
    } 
    else if (readerId.itemLabel) {
      textLinkEl = {
        readerId: undefined,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        readingSelectionCaption: readerId.readingSelectionCaption,
        itemLabel: readerId.itemLabel
      } 
    }
    
    return textLinkEl
  }

  isWidthConstrained() {
    return this.whitelabel.getSiteFlag("TEST_RUNNER_WIDTH_CONSTRAINT");
  }

  isBCED(){
    return (this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'));
  }

  flagCurrentQuestion = () => {
    const qState = this.getActiveQuestionState()
    qState.__meta.isFlagged = !qState.__meta.isFlagged;
  }

  flagKeyPress = (event: KeyboardEvent) => {
      this.flagCurrentQuestion()
  }

  documentMap:Map<number, IQuestionConfig> = new Map();
  activeDocument
  loadDocument(itemId:number){
    return new Promise((resolve, reject) => {
      const item = <any>this.questionSrcDb.get(+itemId);
      // console.log(+itemId, item)
      this.documentMap.set(+itemId, item);
      resolve(item);
    })
  }

  selectDocumentView(itemId:number){
    this.selectedDocumentId = +itemId;
    // console.log('this.activeDocument', +itemId,  this.activeDocument)
    this.activeDocument = this.documentMap.get(+itemId);

    this.logStudentAction("STUDENT_ACCESS_DOC", this.activeDocument);
  }

  ngAfterViewChecked(){
    if (this.styleProfile.getShowScrollIndicationStatus()) {
      this.showScrollIndication()
    }
  }

  ngAfterViewInit(){
    this.testStartTime = this.getCurrentTime();
    const loading = new ElementRef<HTMLElement>(document.querySelector('.loading'));
    this.renderer.addClass(loading.nativeElement, "loading-finished")
    this.studentSecurity.activateInputId(DEFAULT_NOTEPAD_ID, this.testState.notes)
  }

  lastFillState:boolean;
  checkAutoScroll(){
    const currentFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    if (currentFillState !== this.lastFillState){
      if (this.autoScrollOnSelect && currentFillState){
        const el = document.getElementById('btn-submit-question');
        if (el){
          el.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
      }
      this.lastFillState = currentFillState;
    }
  }

  getQuestionRunnerWidth(containerId:string){
    // if (this.currentReadSelDisplayMode == ReaderTextMode.HALF) {
    //   const ghostPix = this.getPixelsPerEM("ghost")
    //   const ghostLeftPix = this.getPixelsPerEM("ghost-split-left")
    //   const pixRatio = ghostPix/ghostLeftPix
    //   const pixelsRemaining = window.innerWidth-20*ghostPix
    //   const emRemaining = pixelsRemaining/ghostLeftPix
    //   return emRemaining/2.2
    // }
    return 40;
  }
  getQuestionRunnerSpillover(containerId:string){
    return 0;
  }

  getIframeURL(){
    let url = window.location.protocol + "//" + window.location.host + "/assets/sci_calc/index.html"
    this.element.url = url;

  }

  enableDrawings() {
    this.isShowOverlay = true
    this.isShowDrawing = true
  }

  setEraser(){
    if (this.isShowingSectionInfo) return;
    this.showLine = false;
    this.showHighlight = false;
    if (this.isShowOverlay && this.showEraser){
      this.disableOverlay()
    }else{
      this.showEraser = true;
      this.enableDrawings()
    }
    this.logTool("SHOW_ERASER", this.showEraser);
  }
  setHighlighter(){
    if (this.isShowingSectionInfo) return;
    this.showEraser = false;
    this.showLine = false;
    if (this.isShowOverlay && this.showHighlight){
      this.disableOverlay()
    }else{
      this.showHighlight = true;
      this.enableDrawings()
    }
    this.logTool("SHOW_HIGHLIGHT", this.showHighlight);
  }
  setLine(){
    if (this.isShowingSectionInfo) return;
    this.showHighlight = false;
    this.showEraser = false;
    if (this.isShowOverlay && this.showLine){
      this.disableOverlay()
    }else{
      this.showLine = true;
      this.enableDrawings()
    }
    this.logTool("SHOW_LINE", this.showLine);
  }
  disableOverlay(){
    this.showEraser = false;
    this.showHighlight = false;
    this.showLine = false;
    this.isShowOverlay = false;
  }
 
  removeAllDrawings(){
      this.clearAllDrawings = true
  }

  resetEventReceivedCount = 0;
  resetClearDrawing(val){
    this.resetEventReceivedCount ++;
    const renderDrawingElements = Array.from(document.getElementsByTagName("element-render-drawing"));
    const renderDrawingElementsCount = renderDrawingElements.length;
    
    if (renderDrawingElementsCount && this.resetEventReceivedCount < renderDrawingElementsCount){} 
    else {
      this.clearAllDrawings = val;
      this.resetEventReceivedCount = 0;
    }
  }

  getDrawingDisplayMode(){
    return DrawDisplayMode.TEST_RUNNER;
  }

  
  initTicker(){
    this.sectionTimeStarted = (new Date()).valueOf();
    // to do: this is not correct... it should be when they start a section and it should be persisted to the db
    this.ticker = setInterval(() => {
      const section = this.getCurrentSection();
      // console.log('section', section)
      this.checkAutoScroll(); // this has nothing to do with the timer countdown disp[lay]
      if (section && section.isTimeLimit) {
        let secondsRemaining = this.getCurrentSection().timeLimitMinutes * 60;
        let secondsSpent = ((new Date()).valueOf() - this.sectionTimeStarted) / 1000;
        secondsRemaining -= secondsSpent;
        let secondsDisplay = Math.round(secondsRemaining % 60);
        // console.log('getSectionTimeRemaining', secondsSpent)
        let minutesDisplay = Math.round((secondsRemaining - secondsDisplay) / 60);
        this.sectionTimeRemaining = this.leadingZero(minutesDisplay) + ':' + this.leadingZero(secondsDisplay);
      }
    }, 100);
    ////

    this.restartQuestionResaveInterval();

    ///
    this.dataGuard.forceSaveSub().subscribe(req => {
      if (req){
        if (this.isNotQuestion()){
          this.logQuestionStateBkp();
        }
        else {
          this._saveQuestion(true);
        }
      }
    })
  }

  private clearQuestionResaveInterval(){
    if (this.questionResaveInterval){
      clearInterval(this.questionResaveInterval);
    }
  }

  private restartQuestionResaveInterval(){
    console.log('restartQuestionResaveInterval')
    this.clearQuestionResaveInterval();
    const ITEM_RESAVE_INTERVAL = 1*90*1000;
    this.questionResaveInterval = setInterval(() => {
      console.log('autosave 1')
      if (!this.isShowingResults){
        if (this.isNotQuestion()){
          this.logQuestionStateBkp();
        }
        else {
          console.log('autosave 2')
          this._saveQuestion(true);
        }
      }
    }, ITEM_RESAVE_INTERVAL)
  }

  initChatPage(){
    this.chatService.isSupervisor = false;
    this.chatService.isInvigilator = false;
    this.chatService.isTestTaker = true;
    this.chatService.uid = this.auth.user().value.uid;
    this.chatService.markingPoolId = this.testSessionId;
    // this.chatService.selectedMarker = this.markerId;
    this.chatService.instit_group_id = this.instit_group_id;
    this.chatService.initSocket();
  }

  getPrevSrcImg() {
    if (this.lang.c()=='en') {
      return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Prev/1634846635533/Prev.png"
    } else {
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Reculer/1635783789537/Revenir.png"
      } else {
        return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Reculer/1635783789537/Reculer.png"
      }
    }
  }

  getPrevText(){
    (this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))? 'btn_prev_revenir_bc' : 'btn_prev_bc'
  }

  getNextSrcImg() {
    if (this.lang.c()=='en') {
      return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Next/1634848698178/Next.png"
    } else {
      return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Avancer/1635783738472/Avancer.png"
    }
  }

  initHelpPage(){
    if (this.helpPageItem){
      this.helpScreenLayout = this.questionSrcDb.get(+this.helpPageItem);
    }
    else{
      return this.auth.apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, 1)
        .then(helpScreenLayout =>{
          this.helpScreenLayout = <any> helpScreenLayout;
        })
    }
  }

  ngOnDestroy() {
    clearInterval(this.ticker);
    this.clearQuestionResaveInterval();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.clearPresenceDetection()
    console.log('clearing')
    this.clearHyperlinkService();
    this.studentSecurity.destroy();
  }

  toggleTextToSpeech() {
    if(!this.isTTStoolEnabled()) return;
    this.textToSpeech.toggle();
    this.logTool("TTS", this.textToSpeech.isActive);
  }
  isTextToSpeechActive() {
    return this.textToSpeech.isActive;
  }
  toggleHelpScreen() {
    if(this.isHelpOverlay){
      // document.getElementById("quest-cont").style.display = "flex"
      this.isHelpOverlay = false;
    }
    else{
      // document.getElementById("quest-cont").style.display = "none"
      this.isHelpOverlay = true;
    }

    this.logTool("HELP", this.isHelpOverlay);
  }
  getHelpScreenLayout(){
    //console.log(this.helpScreenLayout);
    return this.helpScreenLayout;
  }
  private _saveQuestion(isQuietSave:boolean=false, isExitSave:boolean=false) {

    // console.log("_saveQuestion!!!")

    //Incase there's no qId / Question => log the response and 
    if(!this.getActiveQuestionId()){
      this.logQuestionStateBkp();
      reject();
      return;
    }

    if (this.isSavingResponse || this.isShowingResults) {
      reject();
    }
    this.isSavingResponse = true;

    this.isQuietlySavingResponse = isQuietSave;
    this.isTestNavOnTop = true


    const _postSave = () => {
      this.isSavingResponse = false;
      this.isQuietlySavingResponse = false;
      this.isTestNavOnTop = false
    }

    return new Promise<void>( async (resolve, reject) => {
      const saveQuestionAndHandleErrors = async (data: SaveQuestionPayload, is_force_save?:boolean): Promise<void> => {
        return new Promise<void>(async (resolveSave, rejectSave): Promise<void> => {
          this.isSavingResponse = true;
          const saveQuestionPromise = this.saveQuestion({
            test_question_id: data.test_question_id,
            test_question_version_id: data.test_question_version_id,
            question_index: data.question_index,
            question_caption: data.question_caption,
            section_index: data.section_index,
            module_id: data.module_id,
            response_raw: data.response_raw,
            response: data.response,
            is_force_save,
            disableQuestionResponseShrinkageDetection: data.disableQuestionResponseShrinkageDetection            
          });

          Promise.all([saveQuestionPromise, this.studentSecurity.logStudentAction(this.studentSecurity.tempLogs)]).then((responses) => {
            if (responses[0] && responses[0].activeSubSession){
    
            }
            this.studentSecurity.tempLogs = [];            
            this.logDrawing();
            resolveSave();
          })
          .catch((e) => {            
            const questionSaveErr = parseQuestionSaveError(e.message);
            this.checkForOutOfBounds();
    
            if(e.message == "VERIFY_QUESTION_SUBMIT") {
              // Shrinkage detected warn user
              this.loginGuard.confirmationReqActivate({
                caption: this.checkTag('BC_FSA')? this.lang.tra('msg_question_save_shrinkage_detected_BC_FSA') : this.lang.tra('msg_question_save_shrinkage_detected_BC'),
                btnProceedCaption: this.lang.tra('lbl_yes'),
                btnCancelCaption: this.lang.tra('lbl_no'),
                confirm: async() => {
                  await saveQuestionAndHandleErrors(data, true);
                  return resolveSave();
                },
                close: async () => {
                  // Restore previous answer
                  await this.restoreQuestionState(e.data);
                  _postSave()  // close function doesn't resolve the promise so - it will let student stay on the same question after restoring the state.
                }
              })
            }
            else if(e.message === 'PATH_CHANGE_REQUEST') {
              this.handlePathSelChangeReqErr(e)
            }
            else if(!questionSaveErr) {
              let caption = '';
  
              if(this.checkTag('BC_FSA')){
                caption = 'msg_save_question_err_FSA'
              } 
              else if(this.checkTag('BC_GRAD')){
                caption = 'msg_save_question_err_GRAD'
              } 
              else{
                caption = 'msg_save_question_err'
              } 
              
              this.loginGuard.confirmationReqActivate({
                caption,
                hideCancel: true,
                btnProceedCaption: this.lang.tra('btn_retry'),
                confirm: async () => {
                  await saveQuestionAndHandleErrors(data);
                  return resolveSave();
                }
              })
            } else {
              if (e.message == "INVALID_TEST_ATTEMPT") {
                this.loginGuard.confirmationReqActivate({
                  caption: 'msg_invalid_test_attempt',
                  hideCancel: true,
                  btnProceedCaption: this.lang.tra('msg_invalid_test_attempt_button'),
                  confirm: () => {
                    this.route.navigate([`/${this.lang.c()}/bced-landing/grad/secure`])
                  }
                })
              }            
              else{
                // this could be triggered if the student had multiple tabs open
                let slugMsg = this.checkTag('BC_GRAD') ? 'msg_cannot_proceed_test_GRAD': 'msg_cannot_proceed_test';
                let slugErr = this.checkTag('BC_GRAD') && e.message === 'ATTEMPT_CLOSED' ? 'msg_attempt_closed_err_GRAD' : questionSaveErr;
  
                this.checkTag('BC_FSA') ? slugMsg = 'msg_cannot_proceed_test_FSA' : '';
                this.checkTag('BC_FSA') && e.message === 'ATTEMPT_CLOSED' ? slugErr = 'msg_attempt_closed_err_FSA' : '';
  
                this.loginGuard.confirmationReqActivate({
                  caption: this.lang.tra(slugMsg) + ' ' + this.lang.tra(slugErr)
                });
              }
              rejectSave(e);
            }
          });
        })
      }

      let splitScreenQuestionData;

      if(this.shouldSaveSplitScreenItem())
        splitScreenQuestionData = this.getQuestionDataToSave(true)

      // Save the main question response, set the saveReadSelection to true so it will save the split screen item once the main save succeed
      const mainQuestionData = this.getQuestionDataToSave()

      // Exit save is ONLY called on the beforeunload happens, i.e. when user is closing the tab or refreshing. 
      // In that case we just make two separate force save calls for the main and the splitscreen question data (if there is).
      if (!isExitSave){
        await saveQuestionAndHandleErrors(mainQuestionData);
        if (splitScreenQuestionData) {
          await saveQuestionAndHandleErrors(splitScreenQuestionData);
        }
        await this._saveNotes();
        _postSave();
        resolve();
      }
      else {

        // bundle up save calls as Error throwing would not be possible while refreshing
        const queSavePromises = [
          saveQuestionAndHandleErrors(mainQuestionData, true),
          splitScreenQuestionData && saveQuestionAndHandleErrors(splitScreenQuestionData, true),
          this._saveNotes()
        ];        
        
        await Promise.all(queSavePromises)
        _postSave();
        resolve();
      }
      
    });
  }

  private handlePathSelChangeReqErr = (e: {message: string, data: any}) => {
    this.loginGuard.confirmationReqActivate({
      caption: 'msg_path_change_request_err',
      hideCancel: true,
      btnProceedCaption: this.lang.tra('btn_reload'),
      confirm: () => window.location.reload()
    })
  }

  private _saveNotes(){

    const _notes = () => {
      // scoreWeigth Aggregate function checks for score and weight property in the API 
      // to avoid error giving it a key '__notes'
      return  {
        "__notes" : this.testState.notes ? this.testState.notes : ""
      }      
    }

    return new Promise((resolve, reject) => {
      // Save Notes
      this.saveQuestion({
        test_question_id: DEFAULT_NOTES_TEST_QUESTION_ID,
        test_question_version_id: DEFAULT_NOTES_TEST_QUESTION_ID,
        question_index: this.getCurrentQuestionIndex(),
        section_index: this.getCurrentSectionIndex(),
        module_id: this.getCurrentModuleId(),
        response_raw: JSON.stringify(_notes()),
        response: ""
      })
        .then(res => resolve(null))
        .catch(e => {
          console.error("SAVE_NOTES_FAILED", e);
          reject(e);
        });
    })
  }

  private _logQuestionTimeSpent = (isReadSel:boolean) => {
    if(typeof this.logQuestionTimeSpent !== 'function' ) return;
    let question_id = isReadSel ? this.getActiveReadingSelectionId() : this.getActiveQuestionId();
    let startTime = isReadSel ? this.getReadingSelectionState()?.__meta?.startTime ?? undefined : this.getActiveQuestionState()?.__meta?.startTime ?? undefined   
    let endTime = this.getCurrentDateTime();

    if(question_id == null || !startTime ) return;
    const data = { question_id, startTime, endTime };
    this.logQuestionTimeSpent(data);
  }

  showSaving() {
    return this.isSavingResponse && !this.isQuietlySavingResponse
  }

  /**
   * Returns true if split screen item should be saved.
   * @returns true if yes
   */
  shouldSaveSplitScreenItem() : boolean {
    if (!this.activeReadingSelection)
      return false;

    const activeReadingSelectionId = this.getActiveReadingSelectionId();
    const activeQuestionId = this.getActiveQuestionId()
    const activeQuestionReadingSelections = this.getQuestionDef(activeQuestionId).readSelections;

    const scoredEntries = identifyQuestionResponseEntries(this.activeReadingSelection.content, []);
    const activeReadSelLabel = this.activeReadingSelection.label;
    return (
      activeReadingSelectionId !== undefined &&     // active reading selection id is present
      activeQuestionReadingSelections &&            // the current active question has reading selections, and
      activeQuestionReadingSelections.includes(activeReadSelLabel) && // the recorded curr reading selection is one of them
      this.getActiveQuestionReadSel() &&
      this.isQuestionActiveInSplitScreen(activeReadingSelectionId)  &&
      scoredEntries.length > 0
    )
  }

  /**
   * Helper method to get data for question save.
   * @param isSplitItem Flag for if the save is for split screen item.
   * @returns Data that goes to saveQuestionAndHandleErrors() method.
   */
  getQuestionDataToSave(isSplitItem?: boolean): {
    test_question_id: number,
    test_question_version_id: number,
    question_index: number,
    question_caption: string,
    section_index: number,
    module_id: number,
    response_raw: string,
    response: string,
    disableQuestionResponseShrinkageDetection: boolean
  }{

    let test_question_id: number;
    let test_question_version_id: number;
    let question_index: number;
    let question_caption: string;
    let section_index: number;
    let module_id: number;
    let response_raw: string;
    let response: string;
    let disableQuestionResponseShrinkageDetection: boolean = false;

    // For Split Screen
    if (isSplitItem) {
      const content = this.activeReadingSelection;
      test_question_version_id = content ? content.test_question_version_id : 0;
      test_question_id = this.getActiveReadingSelectionId();
      response_raw = JSON.stringify(this.getActiveReadingSelectionState());
      response = ''+(this.getActiveReadingSelectionResponse() || '');
      question_caption = this.getCurrentReadingSelectionTitle();

      // question_index = this.getCurrentReadingSelectionIndex();
      // section_index = this.getCurrentSectionIndex();

      // For now we're assuming these two indexes are being used to recored the last position of the student,
      // so the main item's section and question index. 
      section_index = this.getCurrentSectionIndex();
      question_index = this.getCurrentQuestionIndex();

      module_id = this.getCurrentModuleId();

    } else {
      // For the main question
      const content = this.getActiveQuestionContent();
      test_question_version_id = content ? content.test_question_version_id : 0;
      test_question_id = this.getActiveQuestionId();
      response_raw = JSON.stringify(this.getActiveQuestionState());
      question_index = this.getCurrentQuestionIndex();
      question_caption = this.getCurrentQuestionTitle();
      section_index = this.getCurrentSectionIndex();
      module_id = this.getCurrentModuleId();
      response = ''+(this.getActiveQuestionResponse() || '');
    }

    disableQuestionResponseShrinkageDetection = this.getQuestionDef(test_question_id).disableQuestionResponseShrinkageDetection;

    return {
      test_question_id,
      test_question_version_id,
      question_index,
      question_caption,
      section_index,
      module_id,
      response_raw,
      response,
      disableQuestionResponseShrinkageDetection,
    }
  }

  private _logItemView()  {
    if (typeof this.logItemView !== 'function') return;
    const questionId = this.getActiveQuestionId();
    if (!questionId) return;
    const itemToLog = <IAssetImpressionConfig> { 
      question_id: questionId,
      element_type: 'question' 
    };
    return this.logItemView(itemToLog);
  }

  private _logAssetView() {
    if (typeof this.logAssetView !== 'function') return;
    const question = this.getActiveQuestionContent();
    const questionId = this.getActiveQuestionId();
    if (!questionId) return;
    const assets = question.content.reduce((acc, cv) => {
      const { elementType, images } = cv;
      switch (elementType) {
        case 'image': {
          let assetImages = [];
          if (images.default && images.default.image && images.default.image.assetId) {
            const { assetId, assetVersionId, elementType } = images.default.image;
            assetImages = [...assetImages, <IAssetImpressionConfig> { 
              question_id: questionId, 
              asset_id: assetId, 
              asset_version_id: assetVersionId, 
              element_type: elementType 
            }];
          }
          if (images.selected && images.selected.image && images.selected.image.assetId) {
            const { assetId, assetVersionId, elementType } = images.selected.image;
            assetImages = [...assetImages, <IAssetImpressionConfig> { 
              question_id: questionId, 
              asset_id: assetId, 
              asset_version_id: assetVersionId, 
              element_type: elementType 
            }];
          }
          return [...acc, ...assetImages ]
       }
       default:
        const { assetId, assetVersionId } = cv;
        if (assetId && assetVersionId) {
          const assetToLog = <IAssetImpressionConfig> { 
            question_id: questionId, 
            asset_id: assetId, 
            asset_version_id: assetVersionId, 
            element_type: elementType 
          };
          return [...acc, assetToLog ]
        }
        return acc;
      }
    }, []);
    return this.logAssetView(assets);
  }

  getCurrentModuleId(){
    return this.testState.currentModuleId;
  }

  getLogo(){
    let url;
    if (this.lang.c() === 'en'){
      url = this.whitelabel.getSiteText('asmt_logo_en')
    }
    else if (this.lang.c() === 'fr'){
      url = this.whitelabel.getSiteText('asmt_logo_fr')
    }
    return url;
  }

  getLogoSafe(){
    return this.safeUrl.sanitize(this.getLogo());
  }


  getActiveQuestionResponse() {
    const state = this.getActiveQuestionState();
    const responses = [];
    const entries = this.getQuestionStateEntries(state);
    entries.forEach(eRes => {
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  getActiveReadingSelectionResponse() {
    const state = this.getActiveReadingSelectionState();
    const responses = [];
    const entries = this.getQuestionStateEntries(state);
    entries.forEach(eRes => {
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  // getRouteParams(routeParams: any) {
  //   this.initTestDef();
  //   this.initTestState();
  // }

  testRunnerSections: Partial<ISectionDef>[];
  testRunnerPathSections: Map<number, ISectionDef[]>;
  initTestDef() {
    // reconstruct section order (taking into account choice paths)
    this.testRunnerSections = [];
    this.testRunnerPathSections = new Map();
    let currentConditional:{itemLabel:string, pathSectionList:ISectionDef[]};
    this.currentTestDesign.sections.forEach(section => {
      if (section.isConditional){
        if (currentConditional && currentConditional.itemLabel === section.conditionOnItem){
          currentConditional.pathSectionList.push(section);
        }
        else{
          currentConditional = {
            itemLabel: section.conditionOnItem, 
            pathSectionList: [section]
          }
          const nextIndex = this.testRunnerSections.length;
          this.testRunnerSections.push({
            questions:[],
          });
          this.testRunnerPathSections.set(nextIndex, currentConditional.pathSectionList);
        }
      }
      else{
        this.testRunnerSections.push(section)
      }
    });
    // compute section meta (mostly for the progress bar)
    let qsPrec = 0;
    this.testRunnerSections.forEach(section => {
      const qs = section.questions.length;
      let meta: ISectionMeta = { qs, qsPrec, };
      section.__meta = meta;
      qsPrec += qs;
    });
    const qsTotal = qsPrec;
    // store the total number of questions
    this.currentTestDesign.__meta = {qs: qsTotal};
    // compute the position of the marker on the progress bar
    this.testRunnerSections.forEach(section => {
      const m = section.__meta;
      const qIG = m.qsPrec + m.qs;
      const proportion = qIG / qsTotal;
      const markLoc = this.renderLocProp(proportion);
      section.__meta.markLoc = markLoc;
    });
    // in case we are restoring, initialize path fills
    
  }

  updateScreenShrinkFactor(){
    this.screenWidth = window.innerWidth;
    let baseW = 900;
    const isSplitScreen = this.isShowingLeft() && this.isShowingRight();
    if (isSplitScreen){
      baseW = 1400 
    }
    baseW = baseW * this.defaultZoomLevel;
    if (this.screenWidth < baseW){
      this.zoom.screenShrinkSub.next(this.screenWidth / baseW);
    }
    else if (!isSplitScreen) { // && this.isShowingRight()
      this.zoom.screenShrinkSub.next(Math.min(3000, this.screenWidth) / baseW);
    }
  }

  updateDragZoomCorrection(){
    const cdkStyle:any = document.getElementById('cdk-font-size-override');
    if (cdkStyle){
      if (this.isFlushNavigation()){
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.getScreenShrinkZoom()}em; }`;
      }
      else{
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.getScreenShrinkZoom()}em; }`;
        // cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.zoomLevel}em; }`;
      }
    }
  }

  private renderLocProp(p: number, asNum: boolean= false) {
    return Math.round(100 * p) + (asNum ? '' : '%');
  }

  showSectionInfo() {
    if (this.getCurrentSectionPreambleContent()) {
      this.isShowingSectionInfo = true;
    }
    this.scrollToQuestion();
  }
  hideSectionInfo() {
    this.isShowingSectionInfo = false;
    this.lastFillState = this.isQuestionFilled(this.getCurrentQuestionIndex());
    this.markActiveQuestionAsStarted();
    this.scrollToQuestion();
  }

  defaultProgressContainer = {};
  getProgressContainer(){
    return OSSLT_ASSESSMENT_MODULES || this.defaultProgressContainer;
  }
  getCurrentProgressBySession(session: Object, indexStart: number){
    let qsTotal = 0;
    let totalFilled = 0;
    
    const container = this.getProgressContainer();

    if(this.count > 0){ indexStart = indexStart + container[this.count].length; }

    if(session === this.sessions[this.count]){
      const currSection = this.getCurrentSection(); 
      const currSectionIndex = this.getCurrentSectionIndex();
      const numUnfilledQInSection = this.countNumCurrentQuestionsUnfilled();
      let totalFilledInSection = currSection.questions.length - numUnfilledQInSection;
      this.totalFilledBySection[currSectionIndex] = totalFilledInSection;
      
      for(let el in this.totalFilledBySection ){
        if(this.totalFilledBySection.hasOwnProperty(el)){
          totalFilled += parseInt(this.totalFilledBySection[el]) //sum of all totalFilledBysection
        }
      }

      for( let i=indexStart; i< container[this.count].length+indexStart; i++ ){
        qsTotal += this.currentTestDesign.sections[i].questions.length;
      }
      const proportion = totalFilled / qsTotal; // sum of all totalFilledBySection divided by the total num of questions in session

    return this.renderLocProp(proportion, true);
    } 
  }


  getCurrentProgressLoc(asNum: boolean= false) {
    const section = this.getCurrentSection();
    const qIR = this.getCurrentQuestionIndex() + 1;
    const qsTotal = this.currentTestDesign.__meta.qs;
    const qIG = section.__meta.qsPrec + qIR;
    const proportion = qIG / qsTotal;
    return this.renderLocProp(proportion, asNum);
  }

  initTestState() {

    let sectionIndex = this.sectionIndexInit;
    let sectionsAllowedIndex = undefined;
    if(this.sectionsAllowed) {
      sectionsAllowedIndex = 0;
      sectionIndex = Math.max(sectionIndex, this.sectionsAllowed[sectionsAllowedIndex]);
    }

    // console.log('questionStates', this.questionStates)
    this.testState = {
      languageCode: this.testLang,
      furthestSectionIndex: sectionIndex,
      currentSectionIndex: sectionIndex,
      currentQuestionIndex: this.questionIndexInit,
      currentModuleId: this.moduleIdInit,
      questionStates: this.questionStates,
      currentSectionsAllowedIndex: sectionsAllowedIndex,
      notes: this.getNotes()
    };

    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      // this.loadNextModule();
      if (this.testState.currentSectionIndex > 0){
        let targetModuleId = this.testState.currentModuleId;
        if (!targetModuleId){
          targetModuleId = this.getNextModuleId(this.testState.currentSectionIndex-1);
        }
        this.loadQuestionsForModuleId(+targetModuleId, this.testState.currentSectionIndex);
      } 
      else {
        this.testState.currentModuleId = this.getSection(this.testState.currentSectionIndex).moduleId;
      }
    }
    this.initSection();

    if (this.testState.currentQuestionIndex === 0) {
      if (this.getCurrentSectionPreambleContent()) {
        this.isShowingSectionInfo = true;
      }
    }
    this.checkForOutOfBounds();  
  }

  checkForOutOfBounds(){
    const questions = this.getCurrentQuestions();
    if (this.testState.currentQuestionIndex > questions.length -1){
      this.testState.currentQuestionIndex = 0;
    }
  }

  getNotes = () => {
    let notes = ''
    if(this.asmtFmrk){
      const notesTestQuestionId = this.asmtFmrk['notesTestQuestionId'] ?? DEFAULT_NOTES_TEST_QUESTION_ID;
      if(this.questionStates){
        notes = this.questionStates[notesTestQuestionId]?.__notes || '';
      }
    }
    return notes;
  }

  // Review Page
  isReviewQuestionsEnabled = () => !!this.asmtFmrk.isReviewQuestionsPage;

  isReviewQuestionTimeSpent = () => !!this.asmtFmrk.isReviewQuestionsPageTimeSpent;

  reviewQuestionPage = () => {
    if(this.isReviewQuestionTimeSpent()) { this.calculateTimeSpentQuestions(); }
    this.selectPageMode(this.pageModeFlow.length - 2);
    this.countTotalFlaggedUnAnasweredQuestion()
    this.isOnReviewQuestionsPage = true;   
    this.isReviewQuestionUnlockedforNav = true;
  }
  
  handleReviewPageFilters(type: ReviewPageFilterMode){  
    const isNoFilterSelected = () => !this.reviewPageFilters.flagged && !this.reviewPageFilters.unAnswered && !this.reviewPageFilters.viewAll;

    switch (type) {
      case ReviewPageFilterMode.VIEWALL:
        this.reviewPageFilters.viewAll = !this.reviewPageFilters.viewAll;
        if(this.reviewPageFilters.viewAll){
          this.reviewPageFilters.flagged = false;
          this.reviewPageFilters.unAnswered = false;
        }
        break;
      case ReviewPageFilterMode.UNANSWERED:
        this.reviewPageFilters.unAnswered = !this.reviewPageFilters.unAnswered;
        break;
      case ReviewPageFilterMode.FLAGGED:
        this.reviewPageFilters.flagged = !this.reviewPageFilters.flagged;
        break;    
      default:
        break;
    }
    if(this.reviewPageFilters.flagged || this.reviewPageFilters.unAnswered) this.reviewPageFilters.viewAll = false;
    if(isNoFilterSelected()) this.reviewPageFilters.viewAll = true
    
  }

  isSectionScoringDisabled = (sectionIdx: number) => this.asmtFmrk.partitions?.[sectionIdx]?.disableScoring;

  ensureDisplayQuestion = (qId: number, sectionIdx: number) => {
    return !this.questionSrcDb.get(qId).isReadingSelectionPage && !this.isSectionScoringDisabled(sectionIdx)  && !(this.questionSrcDb as Map<number, IQuestionConfig>).get(qId).isReviewQuestionExculded;
  };

  isQuestionFilledByqId(qId: number) {
    return this.isQuestionStateFilled(this.getQuestionState(qId));
  }

  isQuestionFlaggedByqId(qId: number) {
    return this.getQuestionState(qId).__meta.isFlagged;
  }

  getReviewQuestionText = (id: number) => {    
    return (this.questionSrcDb as Map<number, IQuestionConfig>).get(id).reviewQuestionText ?? '';
  };

  filteredQuestion(qId: number): boolean {
    let { viewAll, flagged, unAnswered } = this.reviewPageFilters;
    if (viewAll) return true;
    let isFlagged = flagged ? this.isQuestionFlaggedByqId(qId) : false;
    let isFilled = unAnswered ? this.isQuestionFilledByqId(qId) : false;
    if (flagged && unAnswered) return !isFilled || isFlagged;
    if (flagged) return isFlagged;
    if (unAnswered) return !isFilled;
    return false;
  }

  setQuestionReviewPage() {
    this.isOnReviewQuestionsPage = !this.isOnReviewQuestionsPage;
  }

  countTotalFlaggedUnAnasweredQuestion = () => {
    // Intended to use for Review Page
    this.totalFlagged = 0;
    this.totalUnAnswered = 0;
    this.testRunnerSections.forEach((sections, sectionIdx) => {
      sections.questions.forEach((questionId, idx) => {
        if(this.ensureDisplayQuestion(questionId, sectionIdx)){
          this.totalFlagged += this.isQuestionFlaggedByqId(questionId) ? 1 : 0;
          this.totalUnAnswered += !this.isQuestionFilledByqId(questionId) ? 1 : 0
        }        
      })
    });
  }

  initNavigationDropdown() {
    if (!this.asmtFmrk) return;
    const sections = this.asmtFmrk.sectionItems;
    const orderedSections = this.asmtFmrk.partitions;
    if (!sections || !orderedSections) return;

    let prevQuestionsLockedBySection = [];
    const dropdownItems = orderedSections.reduce((allSections, section) => {
      // This is not the most reliable way to get the test runner index of the looped section.
      // This is done because there is currently no way to find out current section id.
      const foundSectionIndex = this.testRunnerSections.findIndex(s => {
        if (s.isConditional) return;
        return s.caption === section.description;
      });
      const { id: sectionId } = section;
      const { questions } = sections[sectionId];
      const questionsForNav = questions.reduce((allQuestions, q) => {
        if (!q.isAnchor) return allQuestions;
        const qAnchor = this.setupQuestionAnchor(q, prevQuestionsLockedBySection, foundSectionIndex);
        // If question locked and locked by section then add to array
        if (!qAnchor && q.lock && q.lock.type === 'section') {
          prevQuestionsLockedBySection = [...prevQuestionsLockedBySection, q];
        }
        if (qAnchor) {
          this.isSectionNavDropdownEnabled = true;
          return [...allQuestions, qAnchor];
        }
        return allQuestions;
      }, []);
      return [...allSections, ...questionsForNav];
    }, []);
    this.questionsForNavigation = dropdownItems;

    // Add review page to Nav
    if  (!this.isReviewIncludedInNavigation && this.isReviewQuestionUnlockedforNav && this.isReviewQuestionsEnabled()){ 
      let label = this.lang.tra('review_questions_navigation_label');
      this.questionsForNavigation.push({
        anchorLabel: label,
        id: null,
        isAnchor: true,
        label,
        // lock doesn't work this way so had to apply it with boolean `this.isReviewQuestionUnlockedforNav`.
        lock: {type: "section", by: {sectionIds: [this.getSectionId(this.asmtFmrk.partitions.length - 1).toString()]}}
      });
    }    
  }

  setupQuestionAnchor = (q: ISectionItem, prevQuestionsLockedBySection, sectionIndex: number) => {
    const { lock } = q;

    // If furthest section is past (student clicked Continue) the anchor's section, unlock automatically.
    if (sectionIndex !== -1) {
      if (sectionIndex <= this.testState.furthestSectionIndex) return q;
    }

    if (prevQuestionsLockedBySection.length) {
      // Automatic Lock by Section:
      // If prev question is still locked by section then lock current question too.
      const prevLockPresent = prevQuestionsLockedBySection.find(q => {
        const { sectionIds } = q.lock.by;
        const allCompleted = this.areAllSectionsCompleted(sectionIds);
        return !allCompleted;
      });
      if (prevLockPresent) return; 
    }

    if (!lock) return q;

    switch (lock.type) {
      case 'section': {
        const { sectionIds } = lock.by;
        if (sectionIds) {
          const allFilled = this.areAllSectionsCompleted(sectionIds);
          if (allFilled) return q;
        }
        return;
      }
      case 'item': {
        const { items } = lock.by;
        const allFilled = items.every(item => {
          const qState = this.getQuestionState(item.id);
          return this.isQuestionStateFilled(qState);
        })
        if (allFilled) return q;
        return;
      }
      case 'choice': {
        const { choiceId, entryId, item } = lock.by;
        const mcqButtonLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const qState = this.getQuestionState(item.id);
        const isFilled = this.isQuestionStateFilled(qState);
        if (!isFilled) return;
        const qEntry = qState[entryId];
        if (!qEntry.isPathFollowed) return;
        if (qEntry.selectionsMap && typeof qEntry.selectionsMap.has === 'function' && qEntry.selectionsMap.has(mcqButtonLabels.indexOf(choiceId))) return q;
        if (qEntry.selections && qEntry.selections[0] && qEntry.selections[0].i === mcqButtonLabels.indexOf(choiceId)) return q;
        return;
      }
    }
  }

  areAllSectionsCompleted = (sectionIds: string[]) => {
    const allConditional = sectionIds.every(sId => {
      return this.asmtFmrk.partitions.find(p => p.id === Number(sId)).isConditional === true;
    });
    const search = (sId: string) => {
      const questions = this.getSectionQuestionsById(sId);
      const allFilled = questions.every(q => {
        const qState = this.getQuestionState(q.id);
        return this.isQuestionStateFilled(qState);
      });
      return allFilled;
    };
    return allConditional ? !!sectionIds.find(sId => search(sId)) : !!sectionIds.every(sId => search(sId));
  }

  getSectionQuestionsById = id => {
    const sections = this.asmtFmrk.sectionItems;
    const section = Object.keys(sections).reduce((acc, sId) => {
      if (sId === id) return sections[sId];
      return acc;
    }, null);
    return section ? section.questions : [];
  }

  toggleNavigationDropdown = () => {
    this.initNavigationDropdown();
    if (this.questionsForNavigation.length === 0) return;
    this.isSectionNavDropdownOpen = !this.isSectionNavDropdownOpen;
  };

  logQuestionStateBkp(){
    try {
      const partialPayload:any = {};
      try { partialPayload.uid = this.auth.getUid() } catch(e) { }
      try { partialPayload.test_question_id =  this.getActiveQuestionId() } catch(e) { }
      try { partialPayload.question_index =  this.getCurrentQuestionIndex() } catch(e) { }
      try { partialPayload.section_index =  this.getCurrentSectionIndex() } catch(e) { }
      try { partialPayload.module_id =  this.getCurrentModuleId() } catch(e) { }
      try { partialPayload.response_raw =  JSON.stringify(this.getActiveQuestionState()) } catch(e) { }
      try { partialPayload.response =  this.getActiveQuestionResponse() } catch(e) { }
      this.auth.apiCreate('public/log', {
        slug: 'Q_SKIP_SAVE_BKP',
        data: partialPayload,
      })
    }
    catch(e){
      console.warn('could not run logQuestionStateBkp')
    }
  }

  onQuestionNavigation = (question) => {
    if(question.anchorLabel === this.lang.tra('review_questions_navigation_label')){   
      if(this.isOnReviewPage()){
        this.logQuestionStateBkp();
        return;
      };
      this._saveQuestion()
      .then(() => {
        // Log Question/Reading passage timings
          if(this.testState.readSelItemId) this.readSelViewClose();          
          this._logQuestionTimeSpent(false);
      })
      .then(() => {
        this.testState.currentSectionIndex = this.asmtFmrk.partitions.length;
        this.reviewQuestionPage();
      });
      return;
    }
    const sectionQuestionIndexes = this.testRunnerSections.reduce((allSections, section, sectionIndex) => {
      const { questions } = section;
      const questionIndex = questions.reduce((allQuestions, qId, qIndex) => {
        if (qId === question.id) return qIndex;
        return allQuestions;
      }, null);
      if (questionIndex === 0 || questionIndex > 0) {
        return { sectionIndex, questionIndex };
      }
      return allSections;
    }, { sectionIndex: null, questionIndex: null });
    const { sectionIndex, questionIndex } = <any>sectionQuestionIndexes;
    if (sectionIndex !== null && questionIndex !== null) {

      const _proceed = () => {
        if (sectionIndex !== this.testState.currentSectionIndex) {
          this.sectionTimeStarted = (new Date()).valueOf();
        }
        // this.updatePosition(sectionIndex, questionIndex);
        this.selectQuestion(questionIndex, sectionIndex);
      }

      if(this.isNotQuestion()){
        this.logQuestionStateBkp();
        _proceed();
        return;
      }

      this._saveQuestion().then(() => {
        _proceed();
      });
    }
  }

  questionTitleMap;
  initSection(){
    this.initQuestionAllTitles();
  }
  
  initQuestionAllTitles(){
    this.questionTitleMap = getQuestionTitles(
      this.currentTestDesign.sections, 
      this.questionSrcDb, 
      this.currentTestDesign.useQuestionLabel, 
      this.getQuestionWord(),
      this.lang
    );
    this.questionTitles.emit(this.questionTitleMap);
  }

  useSectionCaptions(){
    return this.currentTestDesign.useSectionCaptions;
  }

  getCurrentSectionCaption(){
    const section = this.getCurrentSection();
    return section.caption
  }

  getCurrentSectionPopupSlug(){
    const section = this.getCurrentSection();
    if(section.customSectionPopup) return section.customSectionPopupSlug;
    return undefined;
  }

  activateModal(caption: string, confirm: any, btnProceedCaption?:string, btnCancelCaption?:string) {
    this.loginGuard.confirmationReqActivate({
      caption,
      confirm,
      btnProceedCaption,
      btnCancelCaption,
    });
  }

  getNextPageSlug(){
    if (this.checkIsOssltTools()){
      return 'osslt_next_page'
    }
    else{
      return 'btn_next_question'
    }
  }

  getCloseDrawingSlug(){
    if(this.isBCED()) return 'draw_tool_exit_bced'
    if(this.checkIsOsslt()){
      return 'draw_tool_exit';

    } else{
      return 'draw_tool_exit_g9';
    }
  }

  allowQuickCollapse(){
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_LEFT_COLLAPSE)){
      return false;
    }
    if (this.whitelabel.getSiteFlag('IS_EQAO')){
      if (!this.checkTag(KNOWN_TEST_RUNNER_TAGS.ALLOW_LEFT_COLLAPSE)){
        return false
      }
    }    
    return true;
  }


  areTestQuestionsSkippable(){
    return !!this.getCurrentSection().areQuestionsSkippable;
  }


  isFormulasAvailable() {
    return this.getCurrentSection().hasFormulas;
  }
  isCalcAvailable() {
    return this.getCurrentSection().hasCalculator;
  }

  isNotepadAvailable() {
    return this.getCurrentSection().hasNotepad;
  }

  getNextSectionIdxWhenConditional(currentIdx: number): number{
    if(!this.asmtFmrk.partitions[currentIdx].isConditional) return currentIdx;
    return this.getNextSectionIdxWhenConditional(currentIdx + 1);
  }

  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }
  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testState.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }

  leadingZero(num) {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  }
  getSectionTimeRemaining() {

  }

  getSection(i: number) {
    return this.testRunnerSections[i] || <any> {questions: []};
  }

  getCurrentQuestions(): number[] {
    if(this.isShowingResults) {
      return this.resultQs;
    } else {
      return (this.getCurrentSection()).questions || [];
    }
  }

  getResultsQuestions(): any[] {
    return this.resultQs;
  }

  getQuestionWordSlug() {
    return this.currentTestDesign.questionWordSlug ? this.currentTestDesign.questionWordSlug : "title_question";
  }

  getQuestionWord() {
    return this.lang.tra(this.getQuestionWordSlug());
  }

  getCurrentQuestionTitle(){
    return (this.getQuestionTitle(this.getCurrentQuestionIndex())) ;
  }

  getCurrentReadingSelectionTitle(){
    return this.activeReadingSelection.caption;
  }

  getQuestionTitle(qIndex: number):string {
    const qId = this.getCurrentQuestions()[qIndex];
    return this.getQuestionTitleFromId(qId);
  }


  getQuestionTitleFromId(qId: number):string {
    return getQuestionTitleFromMap(this.questionTitleMap, qId);
  }

  getCurrentPoints(){
    const question = this.getActiveQuestionContent();
    if (question && !question.ishidePoints){
      return question.points;
    }
  }

  getActiveQuestionId() {
    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    if (!qId) {
      // console.warn('Null question');
    }
    return qId;
  }

  getSectionPreambleContent(section: ISectionDef) {
    const content = this.getQuestionDef(section.preamble);
    return content;
  }
  getCurrentSectionPreambleContent() {
    // replaced getSectionInfoContent
    const section = this.getCurrentSection();
    return this.getSectionPreambleContent(section);
  }

  getActiveQuestionContent() {
    const content = this.getQuestionDef(this.getActiveQuestionId());
    // console.log('content', content)
    return content;
  }

  setActiveReadingSelection() {
    this.activeReadingSelection = undefined;
    const id = this.getActiveQuestionReadSel();
  }

  getReadingSelections() {
    const question = this.getQuestionDef(this.getActiveQuestionId());
    if (question){
      return question.readSelections;
    }
  }

  private getQuestionsByLabel(){
    const questionMap:Map<string, IQuestionConfig> = new Map()
    this.questionSrcDb.forEach((question: IQuestionConfig)=>{
      questionMap.set(question.label, question);
    });
    return questionMap
  }

  getCurrentZoomDisplay(){
    return Math.floor( 100*(this.getZoomLevel()/this.defaultZoomLevel) ) + '%';
  }

  getReadingSelectionCanvases() {
    const readers = [];
    const readSels = this.getReadingSelections();
    const questionMap = this.getQuestionsByLabel();
    const trackReaderLink = (question:IQuestionConfig, canvasElement?:IContentElementCanvas) => {
      let caption, canvasId;
      if (canvasElement){
        caption = canvasElement.caption
        canvasId = canvasElement.readerId
      }
      if (!caption){
        caption = question.caption || question.label;
      }
      readers.push({
        canvasId, 
        readerId: question.label, 
        caption,
        readingSelectionCaption: question.readingSelectionCaption,
        itemLabel: question.label
      })
    }
    if (readSels) {
      readSels.forEach((itemLabel)=>{
        const question = questionMap.get(itemLabel);
        let isAtLeastOneCanvasFound = false;
        if (question) {
          if (question.isReadingSelectionPage){
            question.content.forEach((element:IContentElement)=>{
              if (element.elementType == ElementType.CANVAS) {
                const canvasElement = <IContentElementCanvas> element;
                trackReaderLink(question, canvasElement);
                isAtLeastOneCanvasFound = true;
              }
            })
          }
          if (!isAtLeastOneCanvasFound) {
            trackReaderLink(question);
          }
        }
      })
    }
    this.readerInfo = readers;
    // console.log('readerInfo', this.readerInfo)
    return readers;
  }

  hasReadingSelections() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length>1) return true;
    return false;
  }

  isQuestionActiveInSplitScreen(qId: number) {
    if (!qId) return false;
    const val = this.hyperLinkService.linkRequest.getValue();
    const qLabel = this.getQuestionLabel(qId);
    return val.itemLabel === qLabel;
  }

  hasExactlyOneReadingSelection() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length==1) {
      return true;
    }
    return false;
  }

  isReadingSelectionListToggle() {
    return this.getActiveQuestionContent().isReadingSelectionsAlwaysShown;
  }

  getActiveQuestionReadSel() {
    const readSelections = this.getReadingSelections();
    if (!readSelections) return undefined;
    readSelections.forEach((candReadSel)=>{
      this.questionSrcDb.forEach((question:IQuestionConfig, id:number) => {
        if (question.label===candReadSel) {
          if (question.label === this.itemLabel){
            this.testState.readSelItemId = id;
            this.activeReadingSelection = question;
          } else {
            question.content.forEach((element)=>{
              if (element.elementType==ElementType.CANVAS && element["readerId"]==this.currentReadSelection && this.currentReadSelection) {
                if (this.activeReadingSelection != question) {
                  this.activeReadingSelection = question;
                  this.testState.readSelItemId = id;
                }
              }
            })
          }
        }
      })
    })
    //console.log(readSel)
    if (this.activeReadingSelection) return this.activeReadingSelection.label;
    return undefined;
  }

  getCurrentQuestionId(){
    const config = this.getActiveQuestionContent();
  }

  isCurrentQuestionFilled(entryIds?:string[]) {
    return this.isQuestionFilled(this.getCurrentQuestionIndex(), entryIds);
  }

  isQuestionFlaggedSection(sIndex: number, qIndex: number) {

  }

  isQuestionFlagged(qIndex: number) {
    let qState = this.getQuestionStateByIndex(qIndex);
    if (qState){
      return qState.__meta.isFlagged;
    }
  }

  getQuestionStateEntries(qState:{[key:string]:any}){
    const entryIdsUnfiltered = Object.keys(qState);
    const entryIds = entryIdsUnfiltered.filter(str => str !== '__meta' );
    return entryIds.map(entryId => {
      qState[entryId].entryId = entryId
      return qState[entryId];
    })
  }

  isQuestionFilled(qIndex: number, entryIds?:string[]) {
    let qState = this.getQuestionStateByIndex(qIndex);
    return this.isQuestionStateFilled(qState, entryIds);
  }

  isQuestionStateFilled = (qState: any, entryIds?:string[]) => {
    const hasSpecReqEntries = (entryIds && entryIds.length > 0);
    if (qState) {
      try {
        let isAllFilled = true;
        let numSpecFilled = 0;
        const entries = this.getQuestionStateEntries(qState);
        if (entries.length == 0 && !hasSpecReqEntries){
          return qState.__meta.isStarted; // this means that the state has been initialized (by the user accessing the item, but there is nothing to be filled)
        }
        entries.forEach(entry => {
          if (!entry.isFilled) {
            isAllFilled = false;
          }
          if (hasSpecReqEntries && (entryIds.indexOf(''+entry.entryId) !== -1)  ){
            if (entry.isFilled){
              numSpecFilled ++
            }
          }
        });
        if (hasSpecReqEntries && numSpecFilled < entryIds.length){
          return false;
        }
        return isAllFilled;
      } 
      catch (e) {
        return false;
      }
    }
    return false;
  }

  containsWideLoad() {
    const question = this.getActiveQuestionContent();
    let isMatch = false;
    if (question) {
      question.content.forEach( element => {
        if (element.elementType === ElementType.SBS) {
          isMatch = true;
        }
      });
    }
    return isMatch;
  }

  getQuestionStateByIndex(qIndex:number){
    const questions = (this.getCurrentQuestions() || []);
    const qId = questions[qIndex];
    return this.getQuestionState(qId);
  }

  getQuestionState = (qId:number) => {
    const states = this.getCurrentQuestionStates();
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    if (!qState.__meta){
      qState.__meta = {};
    }
    return qState;
  }
  getActiveQuestionState() {
    const qId = this.getActiveQuestionId();
    return this.getQuestionState(qId)
  }

  getActiveReadingSelectionState() {
    const qId = this.getActiveReadingSelectionId();
    return this.getQuestionState(qId)
  }

  async restoreQuestionState(prevResponse: {response_raw :string, test_question_id:number|string ,[key:string]:any }) {
    if(prevResponse) {

      const { test_question_id, response_raw, section_id, module_id} = prevResponse ;
      // question state restore log 
      await this.auth.apiCreate('public/student/taqr-shrink-log', {
        test_question_id,
        question_index : this.getCurrentQuestionIndex(),
        section_index: section_id,
        module_id,
        response_raw,
        existingRes : {
          response_raw,
          hash: prevResponse.hash
        },
        test_attempt_id: this.testAttemptId,
        uid: this.auth.getUid(),
        hash: this.testAttemptHash,
        is_shrink: 0
      });

      this.questionResetter = false;
      this.changeDetector.detectChanges()
      const prevState = JSON.parse(prevResponse.response_raw || '')
      this.testState.questionStates[prevResponse.test_question_id] = prevState
      this.questionResetter = true;
      this.changeDetector.detectChanges();
    }
  }

  getActiveReadingSelectionId() {
    const rsId = this.testState.readSelItemId;
    return rsId;
  }

  getReadingSelectionState() {
    const qId = this.getActiveReadingSelectionId()
    const state = this.getQuestionState(qId)
    return state;
  }

  getResultQuestionDef(questionId: number) {
    return <IQuestionConfig> this.resultQuestDefs.get(questionId)
  }

  getQuestionDef(questionId: number) {
    return <IQuestionConfig> this.questionSrcDb.get(questionId);
  }
  getQuestionLabel(questionId: number) {
    const question = this.getQuestionDef(questionId);
    if (question){
      return question.label;
    }
  }

  showCurrentQuestionLabel(){
    alert("Item Label for Lookup: " + this.getQuestionLabel(this.getActiveQuestionId()))
  }

  scrollQuestionIntoView() {
    // const el = this.questionDisplay.nativeElement;
    // el.scrollIntoView();
  }

  updatePosition(sectionIndex :number, questionIndex :number) {
    let differentSection = false;
    if (sectionIndex != this.testState.currentSectionIndex) {
      differentSection = true;
    }
    if (differentSection) {
      this.questionResetter = false
      this.changeDetector.detectChanges()
      this.section2Drawing.set(this.testState.currentSectionIndex, this.drawingCtx)
      this.section2PIndex.set(this.testState.currentSectionIndex, this.drawingPageIndexTracker)
      this.questionResetter = true
      this.changeDetector.detectChanges()
    }
    this.testState.currentSectionIndex = sectionIndex;
    this.testState.currentQuestionIndex = questionIndex;
    const item_id = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    this.studentSecurity.initIds(this.auth.getUid(), this.testSessionId, this.testAttemptId, item_id );
    // if (curentQuestionIndex >= 0 && questionIndex != curentQuestionIndex) {
    //   const qId = this.getCurrentQuestions()[curentQuestionIndex];
    //   if (qId && this.questionSrcDb.get(qId)) {
    //     this.testState.currentQuestionIndex = curentQuestionIndex;
    //   }
    // }
    if (this.testState.currentSectionIndex > this.testState.furthestSectionIndex) {
      this.testState.furthestSectionIndex = this.testState.currentSectionIndex;
    }
    this.rightPageId = 0;
    this.leftPageId = 0;
    if(this.studentG9Connection) {
      this.studentG9Connection.updateStudentPosition({
        stageIndex: this.testState.currentSectionIndex,
        questionIndex: this.testState.currentQuestionIndex,
      });
    }
    this.disableOverlay()
    if (differentSection) {
      this.setDefaultZwibblerContext();
    }
    this.reinitReadingSelection()
  }
 

  selectSectionAndQuestion(sectionIndex, questionIndex) {
    const _proceed = () => {
      if (sectionIndex !== this.testState.currentSectionIndex) {
        this.sectionTimeStarted = (new Date()).valueOf();
      }
      this.updatePosition(sectionIndex, questionIndex);
      this.scrollQuestionIntoView();
    }

    if(this.isNotQuestion()){
      this.logQuestionStateBkp();
      _proceed();
      return;
    }
    
    this._saveQuestion()
    .then(() => {

      this._logQuestionTimeSpent(false)
    })
    .then(() => {
      _proceed();
    });
  }

  logDrawing() {
    this.drawLog.commitLogAndClear(this.testState, this.testSessionId);
  }

  getLineReaderCaption(){
    if (this.checkIsOsslt() || this.checkIsOssltTools()){
      return 'btn_line_reader_osslt';
    }
    else {
      return 'btn_line_reader';
    }
  }

  clearReadingSelection() {
    this.activeReadingSelection = undefined;
    this.currentReadSelection = undefined;
    this.currentBookmark = undefined
    this.itemLabel = undefined;
    this.testState.currentReadingPassageId = undefined;
    this.testState.readSelItemId = undefined;
  }

  questionResetter = true;
  selectQuestion(questionIndex, sectionIndex?) {
    this.questionResetter = false;
    this.changeDetector.detectChanges();
    this.selectPageMode(this.pageModeFlow.length - 1); //index of the TEST_RUNNER page mode
    if(questionIndex < 0) {
      return;
    }
    this.isShowingSectionInfo = false;
    this.isHelpOverlay = false;
    // document.getElementById("quest-cont").style.display = "flex"
    if(this.isShowOverlay){
      this.disableOverlay()
    }

    const updateQuestionIndex = () => {
      this.updatePosition(sectionIndex ?? this.testState.currentSectionIndex, questionIndex);
      this.testState.currentQuestionIndex = questionIndex;
      this.markActiveQuestionAsStarted();
      // try { this.closePassage() } catch (e) {}
      this.reinitReadingSelection()
      this.clearTools();
      this.lastFillState = this.isQuestionFilled(questionIndex);
      this.scrollToQuestion();
      this.isOnReviewQuestionsPage = false;
      this.questionResetter = true;
      this.changeDetector.detectChanges();
    }

    if(this.isShowingResults) {
      updateQuestionIndex();
    } 
    else {

      if(this.isNotQuestion()) {
        this.logQuestionStateBkp();
        updateQuestionIndex();
        return;
      }
      this._saveQuestion()
      .then(() => {
        // Log Question/Reading passage timings
          if(this.testState.readSelItemId) this.readSelViewClose();          
          this._logQuestionTimeSpent(false);
      })
      .then(() => updateQuestionIndex())
      .then(() => this._logItemView())
      .then(() => this._logAssetView())
    }
  }

  isNotQuestion = () => this.isOnReviewPage() || !this.getActiveQuestionId()

  isOnReviewPage = () => (this.isOnReviewQuestionsPage && this.pageMode === PageMode.REVIEW_QUESTIONS)
  
  currentReadSelDisplayMode:ReaderTextMode;
  reinitReadingSelection(){
    const lastReadSelId = this.currentReadSelection;
    // this.hyperLinkService.linkRequest.next({
    //   readerElementId: undefined,
    //   readerId: undefined,
    // });
    this.getReadingSelectionCanvases();
    this.readerInfo && this.readerInfo.length
    const nextReadSel = this.readerInfo ? this.readerInfo[0] : null;
    const isSameReadingSelection = nextReadSel && ((nextReadSel.canvasId && lastReadSelId === nextReadSel.canvasId) || (nextReadSel.readerId && lastReadSelId === nextReadSel.readerId))
    if ( !nextReadSel || !isSameReadingSelection){
      // this.clearReadingSelection();
      this.isShowingReadingSelections = false;
      this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
      this.hyperLinkService.linkRequest.next({})
      this.resetFlag();
    }
    if (nextReadSel && this.getActiveQuestionContent().isStartHalf){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
      if (!isSameReadingSelection){
        this.openDefaultTextLink();
        this.resetFlag();
      }
    }
    else if (isSameReadingSelection){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    }

    if (isSameReadingSelection){
      this.canvasService.bump()
    }
    this.onResize();
  }
  readSelViewFull(){
    this.currentReadSelDisplayMode = ReaderTextMode.FULL;
    this.onResize();
  }
  readSelViewHalf(){
    this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    this.onResize();
    // setTimeout(function () {
    //   const element = document.getElementsByClassName("split-view-right")[0];
    //   if (element) {
    //     element.scrollTop = element.scrollHeight;
    //   }
    // });
  }

  readSelViewClose(){
    console.log('readSelViewClose')
    this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
    this.hyperLinkService.linkRequest.next({});
    this.onResize();
  }

  isShowingLeft(){
    if(this.isShowingResults) {
      return true;
    }
    if (this.getActiveQuestionReadSel()){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.FULL:
            return false;
        case ReaderTextMode.HALF:
        case ReaderTextMode.CLOSED:
            return true;
      }
    }
    return true;
  }
  isShowingRight(){
    /*if(this.isShowingResults) {
      return false;
    }*/
    if (this.getActiveQuestionReadSel()){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.CLOSED:
          return false;
        case ReaderTextMode.FULL:
        case ReaderTextMode.HALF:
            return true;
      }
    }
    return false;
  }

  toggleInfoModal() {
    this.loginGuard.toggleInfoOverlay()
  }

  private scrollElsToByClass = (ids:string, isToBott:boolean=false, positionY?:number) => {
    const elements = document.getElementsByClassName(ids);
    if (elements){
      for (let i=0; i<=elements.length; i++){
        const el = elements[i];
        if (el){
          if (positionY === undefined){
            if (isToBott){
              positionY = el.scrollHeight;
            }
            else {
              positionY = 0;
            }
          }
          el.scrollTo(0, positionY)
        }
      }
    }
  }

  questionNavScrollToEnd(){
    this.scrollElsToByClass('question-navbar-container', true);
  }
  questionNavScrollToStart(){
    this.scrollElsToByClass('question-navbar-container', false);
  }
  questionNavScrollToQuestion(){
    // this.scrollElsToByClass('test-questions', false);
    try {
      const containerEl = document.getElementsByClassName('question-navbar-container')[0];
      const targetEl = document.getElementsByClassName('question-navbar-block is-active')[0];
      if (containerEl && targetEl){
        var containerRect = containerEl.getBoundingClientRect();
        var targetRect = targetEl.getBoundingClientRect();
        var top = targetRect.top - containerRect.top;
        var scrollTarget = containerEl.scrollTop;
        var isReqScrollDown = (top + targetRect.height) > containerRect.height;
        var isReqScrollUp = top  < 0;
        // console.log('scroll', {
        //   scrollTarget,
        //   y: top,
        //   y2: top + targetRect.height,
        //   h: containerRect.height,
        //   isReqScrollDown, 
        //   isReqScrollUp,
        // })
        if (isReqScrollDown){
          scrollTarget += top - (containerRect.height - targetRect.height)
        }
        if (isReqScrollUp){
          scrollTarget += top
        }
        // containerEl.scrollTo(0, scrollTarget)
        

        containerEl.scrollTo({
          top: scrollTarget,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
    catch(e){}
  }

  scrollNavToEnd = () => {
    try {
      const containerEl = document.getElementsByClassName('question-navbar-container')[0];
      if (!containerEl) return;
      const scrollHeight = containerEl.scrollHeight;
      const height = containerEl.getBoundingClientRect().height;
      containerEl.scrollTo(height,scrollHeight)
    } catch (error) {}    
  }

  scrollToQuestion(){
    // content
    window.scrollTo(0, 0);
    this.scrollElsToByClass('split-view-left');
    // this.scrollElsToByClass('split-view-right');
    this.scrollElsToByClass('helper-tools');
    // sidebar
    setTimeout(() => this.questionNavScrollToQuestion(), 100)
  }

  openDefaultTextLink(){
    if (this.readerInfo && this.readerInfo.length === 1){
      const readSel = this.readerInfo[0];
      this.hyperLinkService.linkRequest.next({
        readerElementId: undefined,
        readerId: readSel.readerId,
        itemLabel: readSel.itemLabel
      })
      this.onResize();
      return readSel;
    }
  }



  markActiveQuestionAsStarted(){
    const qState = this.getActiveQuestionState();
    qState.__meta.isStarted = true;
    this.updateQuestionStartTime();
  }

  updateQuestionStartTime = () => {
    // log question starting time 
    const qState = this.getActiveQuestionState();
    qState.__meta.startTime = this.getCurrentDateTime();
  }

  clearTools(){
    this.isFormulasToggledOn = false;
    this.isCalcToggledOn = false;
  }

  gotoPrev() {
    if (this.isOnReviewQuestionsPage && this.testState.currentSectionIndex == this.asmtFmrk.partitions.length){
      this.gotoPrevSection();
      return;
    }
    if(this.isShowingResults && this.pageModeIndex > 0 && this.getCurrentQuestionIndex() === 0) {
      this.gotoPrevPageMode();
      return;
    }
    if (this.blockMoveAwayFromReqQuestion()){
      return;
    }
    
    if (this.getCurrentQuestionIndex() <= 0){    
      if(this.hasPreambleContent()) {
        this.showSectionInfo();
        return;
      }
      this.gotoPrevSection()
      return
    }
    this.gotoPrevQuestion()
  }

  selectPageMode(index: number) {
    this.pageModeIndex = index;
    this.pageMode = this.pageModeFlow[index].slug;
  }

  getCurrentPageModeCaption = () => this.pageModeFlow[this.pageModeIndex].caption;

  gotoPrevPageMode() {
    this.selectPageMode(this.pageModeIndex - 1);
    // Do not display review page in the results section
    if(this.pageModeIndex === this.pageModeFlow.length - 2 && this.isShowingResults) this.selectPageMode(this.pageModeIndex - 1);
  }

  gotoNextPageMode() {
    this.selectPageMode(this.pageModeIndex + 1);
    // Do not display review page in the results section
    if(this.pageModeIndex === this.pageModeFlow.length - 2 && this.isShowingResults) this.selectPageMode(this.pageModeIndex + 1);
    if(this.pageModeIndex === this.pageModeFlow.length - 1){
      this.selectQuestion(0);
    }
  }

  blockMoveAwayFromReqQuestion(){
    if(!this.isShowingResults) {
      const question = this.getActiveQuestionContent();
      // console.log('blockMoveAwayFromReqQuestion', question.isReqFill)
      if (question && question.isReqFill){
        let entryIds = [];
        if (question.reqFillEntries){
          entryIds = question.reqFillEntries.split(',').map(str => str.trim())
        }
        if (!this.isCurrentQuestionFilled(entryIds)){
          if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
            this.loginGuard.quickPopup(question.reqFillMsg, this.lang.tra('btn_alert_bc_cannot_proceed_confirm'));
          } else {
            this.loginGuard.quickPopup(question.reqFillMsg);
          }
          return true;
        }
      }
    }
    return false;
  }

  showPrevNext(){
    if (this.isShowingResults && (this.pageMode !== PageMode.TEST_RUNNER)){
      if (this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled){
        return false;
      }
    }
    return true;
  }

  gotoNext() {
    if (this.onLastSection() && !this.checkIsOsslt() && this.isOnReviewPage()) {
      this.confirmAndSubmitTest(); 
      return; 
    }
    
    if(this.isShowingResults && this.pageModeIndex < this.pageModeFlow.length - 1) {
      return this.gotoNextPageMode();
    }
    if (this.isHelpOverlay){
      return this.isHelpOverlay = false;
    }
    if (this.isShowingSectionInfo){
      if(this.getCurrentQuestions().length == 0){
        this.hideSectionInfo()  
        return this.reviewAndSubmit();
      }
      return this.hideSectionInfo()
    }

    if(!this.isShowingResults) {
      if (this.blockMoveAwayFromReqQuestion()){
        return;
      }
    }

    if (this.isOnLastQuestion()){
      this.scrollNavToEnd()
      if(!this.isShowingResults) {
        return this.reviewAndSubmit();
      } 
      else {
        return this.leaveResults();
      }
    }

    this.gotoNextQuestion()
  }

  gotoNextQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex + 1);
  }

  gotoPrevQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex - 1);
  }

  isOnLastQuestion() {
    return this.testState.currentQuestionIndex >= this.getCurrentQuestions().length - 1;
  }

  countNumCurrentQuestionsUnfilled() {
    let numUnfilled = 0;
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return 0;
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex) && !this.questionSrcDb.get(qId).isReadingSelectionPage) {
        numUnfilled ++;
      }
    });
    return numUnfilled;
  }

  countNumberofFlaggedQuestions = () => {
    const flaggedQuestions = this.returnArrayOfFlaggedQuestions();
    return flaggedQuestions.length;
  }

  returnArrayOfUnfilledQuestions = () => {
    let array = [];
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return [];
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex)) {
        array.push(this.getQuestionTitle(qIndex));
      }
    });
    return array;
  }

  returnArrayOfFlaggedQuestions = () => {
    let flaggedQuestion = []
    this.getCurrentQuestions().forEach((qId, i) => {
      const qState = this.getQuestionState(qId);
      if (qState.__meta.isFlagged){
        flaggedQuestion.push(i)
      }
    })
    return flaggedQuestion;
  }

  private confirmAndSubmitTest(){
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF)){
      this._submitTest();
      return;
    }
    const arrayOfFlaggedQuestions = this.returnArrayOfFlaggedQuestions();
    const arrayOfUnfilledQuestions = this.returnArrayOfUnfilledQuestions();
    // const numFlagged = arrayOfFlaggedQuestions.length;
    // const numUnfilled = arrayOfUnfilledQuestions.length;
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    const {preMessage, cancelLabel} = this.getSubmitPreMessage()

    let dialogText: string;
    let confirmBtnMsg: string;
    let cancelBtnMsg: string;
    const customDlg = this.customConfirmTestDialogData; 
    if (customDlg) {
      dialogText = this.lang.tra(customDlg.text);
      confirmBtnMsg = this.lang.tra(customDlg.confirmMsg);
      cancelBtnMsg = this.lang.tra(customDlg.cancelMsg);
    } 
    else {
      dialogText = preMessage + this.lang.tra(this.getAlertKKSubmitTestSlug());
      confirmBtnMsg = this.goBackAndReviewYesNavigation(true);
      cancelBtnMsg = this.goBackAndReviewNoLabel();
    }

    if (this.isFlushNavigation()){
      if(this.checkTag('BC_GRAD')){
        dialogText = this.lang.tra('alert_bc_section_submit_final_BC_GRAD');
      } else {
        dialogText = this.lang.tra('alert_bc_section_submit_final_BC');
      }
    }
    if (this.asmtFmrk && this.asmtFmrk.msgFinalSubmission){
      dialogText = this.asmtFmrk.msgFinalSubmission;
    }

    let customPopupSlug = this.isOnReviewPage() ? 'reviewQuestions' : this.asmtFmrk.partitions[this.asmtFmrk.partitions.length - 1].customSectionPopupSlug;
    let customSectionPopup = this.styleProfile.getSectionPopup(customPopupSlug, true); 
    if (this.isFlushNavigation() && customSectionPopup ){
        dialogText = customSectionPopup.text;
        confirmBtnMsg = customSectionPopup.nextBtnText;
        cancelBtnMsg = customSectionPopup.backBtnText
    }
    
    const _proceed = () => {
      if (numUnfilled > 0 && numFlagged > 0) {
        this.endSection.emit({hasUnfilled: true, hasFlags: true, arrayOfFlaggedQuestions, arrayOfUnfilledQuestions})
      } 
      else if (numUnfilled > 0 && numFlagged === 0) {
        this.endSection.emit({hasUnfilled: true, hasFlags: false, arrayOfUnfilledQuestions})
      } 
      else if (numUnfilled === 0 && numFlagged > 0) {
        this.endSection.emit({hasUnfilled: false, hasFlags: true, arrayOfFlaggedQuestions})
      } else {
        this.endSection.emit({hasUnfilled: false, hasFlags: false})
      }
      this.isOnReviewQuestionsPage = false;
      this._submitTest();
    }

    this.activateModal(dialogText, () => {
      if(this.isOnReviewPage()){
        this.logQuestionStateBkp();
        _proceed();
      } 
      else {
        this._saveQuestion()
        .then(() => {
          // Log Question/Reading passage timings
            if(this.testState.readSelItemId) this.readSelViewClose();          
            this._logQuestionTimeSpent(false);
        })
        .then(() => _proceed())
      }
      
    }, confirmBtnMsg, cancelBtnMsg );
  }

  private async _submitTest(){
    if(this.isFlushNavigation()) {
      this.scrollToQuestion()
      await this.scoreAllQuestions();
    }
    this.submitTest().catch((e) => {
      let slugs = {}
      if(this.checkTag('BC_GRAD')){
        slugs = {
          slugMsg: 'msg_may_not_submit_test_GRAD',
        }        
      }
      else if( this.checkTag('BC_FSA')){
        slugs = {
          slugMsg: 'msg_may_not_submit_test_FSA',
        }     
      }
      handleSubmitTestErr(e, this.loginGuard, this.lang, slugs);
    });
  }

  isAnyFlaggedQuestions(){
    return this.countNumberofFlaggedQuestions() > 0;
  }

  isFlaggingEnabled(){
    const section = this.getCurrentSection();
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_FLAGGING)){
      return false
    }
    if (section){
      return !section.disableFlagging;
    }
  }

  isLeftBarDisabled(){
    if (!this.isShowingResults){
      // todo: might need to add a flag for show results
      const section = this.getCurrentSection();
      if (section){
        return section.disableLeftBar;
      }
    }
    return false;
  }


  isFlagEnabledForCurrentQuestion(){
    if (this.isFlaggingEnabled()){
      const q = this.getActiveQuestionContent();
      if (q){
        return !(q.isReadingSelectionPage);
      }
    }
  }

  isCurrentQuestionFlagged(){
    const qState = this.getActiveQuestionState()
    return qState.__meta.isFlagged;
  }

  getFilledAndFlagged(){
    return {
      numUnfilled: this.countNumCurrentQuestionsUnfilled(),
      numFlagged: this.countNumberofFlaggedQuestions()
    }
  }

  isQuestionCorrect(id: number) {
    return this.questionScores.get(id) === QuestionScore.CORRECT;
  }

  isQuestionUnmarked(id: number) {
    return this.questionScores.get(id) === QuestionScore.UNMARKED;
  }

  getPrintElement(id: number) {
    const quest = this.getResultQuestionDef(id)
    let element:IContentElement = undefined
    if (quest) {
      quest.content.forEach((el)=>{
        if (el.elementType === ElementType.RESULTS_PRINT) {
          element = el
        }
      })
    }
    return element
  }

  getSubmitPreMessage(){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    let preMessage = '';
    let cancelLabel = this.goBackAndReviewNoLabel();
    let sectionPopup = this.styleProfile.getSectionPopup(this.getCurrentSectionPopupSlug()); 
    if (this.isFlushNavigation()){
      let popupText = sectionPopup?.text || 'alert_bc_section_submit';
      if (popupText == 'alert_bc_section_submit' && this.checkTag('BC_FSA'))
        popupText = 'alert_bc_section_submit_FSA';
      preMessage += this.lang.tra(popupText , undefined, {numUnfilled, numFlagged}) + ' ';
    }
    // else if (this.checkIsOsslt()) {
    //   preMessage += this.lang.tra('osslt_confirm_submit')
    //   cancelLabel = this.goBackAndReviewNoLabel()
    // } 
    else if (this.checkIsOssltTools()) {
      cancelLabel = this.lang.tra('osslt_tools_message')
    }
    else{
      if (this.checkIsOsslt()) {
        cancelLabel = this.goBackAndReviewNoLabel()
      } 
      const isAnyUnfilled = numUnfilled > 0;
      const isAnyFlagged = numFlagged > 0;
      const msgUnfilledAndFlagged = this.lang.tra('alert_UNFILLED_WARN_P1') + ' '+ numUnfilled + " question(s) " + this.lang.tra('osslt_flagged') + ' ' + numFlagged + ' ' + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      const msgUnfilledAndNotFlagged = this.lang.tra(this.getAlertNumUnfilledSlug(), undefined, {questionNum: numUnfilled});
      const msgFilledAndFlagged = this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';;
      if (isAnyUnfilled) {
        if (isAnyFlagged){
          preMessage += msgUnfilledAndFlagged;
        }
        else{
          preMessage += msgUnfilledAndNotFlagged;
        }
      } 
      else if (numUnfilled > 0 && numFlagged === 0) {
        preMessage += this.lang.tra('alert_UNFILLED_WARN_P1')+ ' ' + numUnfilled + " " + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      } 
      else if (numUnfilled === 0 && numFlagged > 0) {
        preMessage += this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      }
    }
    return {preMessage, cancelLabel};
  }

  reqFillingMsgs = []; //For Oral Pop-up
  checkForUnfilledRequiredSectionQuestions(){
    this.reqFillingMsgs = [];
    let missingRequiredQuestion = false;
    this.getCurrentQuestions().forEach((qId, qIndex) => {
      const question = this.getQuestionDef(qId);
      if (question.isReqFill && !this.isQuestionFilled(qIndex)){
        missingRequiredQuestion = true;
        if (question.reqFillMsg)
          this.reqFillingMsgs.push(question.reqFillMsg);
        // console.log('missingRequiredQuestion', qId, question)
      }
    });
    return missingRequiredQuestion;
  }

  reviewAndSubmit() {
    this.isNotepadEnabled = false;
    if (this.checkForUnfilledRequiredSectionQuestions()){
      let message = 'lbl_questions_req_unfilled'
      const section = this.getCurrentSection();
      if (section.msgReqFill){
        if (this.lang.getCurrentLanguage() == 'fr' && section.msgReqFill.indexOf('écouteurs') > -1 && section.msgReqFill.indexOf('microphone') > -1 && this.reqFillingMsgs.length > 0){
          let isMicNotChecked = false;
          let isEarphoneNotChecked = false;
          let micNotCheckedMsg = '';
          let earPhoneNotCheckedMsg = '';
          this.reqFillingMsgs.forEach(msg => {
            if (msg.indexOf('microphone') > -1){
              micNotCheckedMsg = msg
              isMicNotChecked = true;
            }
            if (msg.indexOf('écouteurs') > -1){
              earPhoneNotCheckedMsg = msg;
              isEarphoneNotChecked = true;
            }
          })
          if (isMicNotChecked && isEarphoneNotChecked)
            message = section.msgReqFill
          else if (isMicNotChecked)
            message = micNotCheckedMsg;
          else message = earPhoneNotCheckedMsg;
          
        } else message = section.msgReqFill;
      }
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        this.loginGuard.quickPopup(this.lang.tra(message), this.lang.tra('btn_alert_bc_cannot_proceed_confirm'))
      } else {
        this.loginGuard.quickPopup(this.lang.tra(message))
      }
      return;
    }
    if(this.isOnReviewPage()){
      this.confirmAndSubmitTest();
      return;
    }

    if(!this.getCurrentQuestions().length && !this.onLastSection()) return this.gotoNextSection();

    this._saveQuestion().then(() => {
      // console.log('onLastSection', this.onLastSection(), this.testRunnerSections.length, this.testState.currentSectionIndex );
      if (this.onLastSection() && !this.checkIsOsslt()) {
        if(this.asmtFmrk.isReviewQuestionsPage && this.pageMode !== PageMode.REVIEW_QUESTIONS){
          this.confirmAndSubmitSection(true);          
        } else {          
          this.confirmAndSubmitTest();
        }
      }
      else {
        this.confirmAndSubmitSection(); // checks need
      }
    })
  }

  leaveResults() {
    if (this.frameWorkTagsRef.get('DISABLE_LEAVE_RESULTS_POPUP')) {
      this.exitResults()
      return;
    }
    let msgObj = this.styleProfile.getStyleProfile()[this.lang.c()].configuration?.testRunner?.exitPopup?.text
    let msg = this.lang.tra("tr_results_exit")
    if (this.checkTag('BC_FSA')){
      msg = this.lang.tra('tr_results_exit_FSA')
    }
    if (msgObj) {
      msg = String(msgObj)
    }
    this.activateModal(msg, () => {
      return this.exitResults();
    }, this.lang.tra('lbl_yes'), this.lang.tra('lbl_no'));
  }

  onLastSection() {
    if(this.sectionsAllowed && !this.isBCED()) {
      return this.testState.currentSectionIndex === this.sectionsAllowed[this.sectionsAllowed.length - 1];
    }
    return this.testState.currentSectionIndex >= this.testRunnerSections.length - 1
  }

  isSafari10() {
    const agent = navigator.userAgent.toLowerCase()
    if (agent) {
      if (agent.indexOf("safari")!=-1 && agent.indexOf("version/10")!=-1) {
        return true
      }
    }
  }

  private getSubmitSectinoEmitMsg(){
    if (this.isFlushNavigation()){
      return '';
    }
    else if (this.checkIsOsslt() && this.onLastSection()) {
      return this.lang.tra('osslt_confirm_submit_test')
    } 
    else if (this.checkIsOsslt()) {
      return this.lang.tra('osslt_confirm_submit')
    }
    else {
      return this.lang.tra(this.getAlertKKSubmitSectionSlug())
    }
  }

  private emitSectionEndData(context: {numUnfilled:number, numFlagged:number}){
    const {numUnfilled, numFlagged} = context;
    if (numUnfilled > 0 && numFlagged > 0) {
      this.endSection.emit({hasUnfilled: true, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions(), arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()})
    } else if (numUnfilled > 0 && numFlagged === 0) {
      this.endSection.emit({hasUnfilled: true, hasFlags: false, arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()})
    } else if (numUnfilled === 0 && numFlagged > 0) {
      this.endSection.emit({hasUnfilled: false, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions()})
    } else {
      this.endSection.emit({hasUnfilled: false, hasFlags: false})
    }
  }

  private confirmAndSubmitSection(gotoReview?:boolean){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    const {preMessage, cancelLabel} = this.getSubmitPreMessage();
    const { isSectionForcedPopup } = this.getCurrentSection();
    const proceed = () => {
      this.emitSectionEndData({numUnfilled, numFlagged});
      this.gotoNextSection();
    }
    // Fails when the current section is a part of Choosen path: maybe use this.getNextSectionIdxWhenConditional(currentSectionIdx)
    const nextSectionIndex = this.getCurrentSectionIndex() + 1;
    this.ensureSectionPathFill(nextSectionIndex)
      .then(()=> {
        if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF) || (this.isFlushNavigation() && (numUnfilled === 0) && (numFlagged===0) && !isSectionForcedPopup)){
          proceed();
          if(gotoReview) this.reviewQuestionPage();
        }
        else{
          let navCollapsed = true
          if (!this.isTestNavExpanded) {
            navCollapsed = false
            this.isTestNavExpanded = true;
          }
          this.activateModal(preMessage + this.getSubmitSectinoEmitMsg(), () => {
            this._saveQuestion()
            .then(() => {
              // Log Question/Reading passage timings
                if(this.testState.readSelItemId) this.readSelViewClose();          
                this._logQuestionTimeSpent(false);
            })
            .then(() => {
              proceed();
              if(gotoReview) this.reviewQuestionPage();
              if (!navCollapsed) {
                this.isTestNavExpanded = false;
              }
            });
          }, this.goBackAndReviewYesNavigation(), cancelLabel );
        }
      })
  }

  private gotoPrevSection(){
    const _proceed = () => {
        this.clearTools();         
        const nextSectionIndex = this.testState.currentSectionIndex - 1;
        const nextQuestions = this.testRunnerSections[nextSectionIndex].questions;
        this.updatePosition(nextSectionIndex, nextQuestions.length - 1);
        this.isShowingSectionInfo = false;
        this.questionNavScrollToEnd()
        if(this.hasPreambleWithNoQuestions()) this.showSectionInfo();
    }

    if (this.testState.currentSectionIndex > 0 && this.isFlushNavigation()){
      if(this.isOnReviewPage()){
        this.isOnReviewQuestionsPage = false;
        this.selectPageMode(this.pageModeFlow.length - 1);
        this.logQuestionStateBkp();
        _proceed();
        return;
      }
      if(this.getCurrentQuestions().length == 0) return _proceed();
      this._saveQuestion()
      .then(() => {
        // Log Question/Reading passage timings
          if(this.testState.readSelItemId) this.readSelViewClose();          
          this._logQuestionTimeSpent(false);
      })
      .then(() => _proceed())
    }
  }

  private hasPreambleWithNoQuestions() {
    const { questions } = this.testRunnerSections[this.getCurrentSectionIndex()];
    return this.hasPreambleContent() && questions.length === 0;
  }

  private hasPreambleContent() {
    return !this.isShowingSectionInfo && this.getSectionPreambleContent(this.getCurrentSection());
  }

  getAlertNumUnfilledSlug(): string {
    return this.checkIsOsslt() ? 'osslt_alert_unfilled_questions' :'g9_alert_unfilled_questions';
  }


  private gotoNextSection(){
    sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
    this.clearTools();
    let nextSectionIndex;
    if(!this.isBCED() && this.sectionsAllowed && this.testState.currentSectionsAllowedIndex !== undefined) {
      nextSectionIndex = this.sectionsAllowed[++this.testState.currentSectionsAllowedIndex];
    } 
    else {
      nextSectionIndex = this.testState.currentSectionIndex + 1;
    }
    console.log('nextSectionIndex', nextSectionIndex)
    this.processRouting(nextSectionIndex)
      .then(()=>{
        this.updatePosition(nextSectionIndex, 0);
        try {
          this.closePassage();
        }
        catch(e){}
        this.lastFillState = this.isCurrentQuestionFilled();
        const isPreambleAvail = !!this.getCurrentSectionPreambleContent();
        if (isPreambleAvail) {
          this.isShowingSectionInfo = true;
        }
        else {
          this.isShowingSectionInfo = false;
        }
        this.initFirstQuestion();
        this.questionNavScrollToStart()
        // console.log('section', this.getCurrentSection())
      })
  }

  private processRouting(nextSectionIndex:number){
    return new Promise((resolve, reject) => {
      if (this.testFormType === TestFormConstructionMethod.MSCAT){
        this.loadNextModule();
        resolve(null);
      }
      else{
        this.ensureSectionPathFill(nextSectionIndex)
          .then(resolve)
          .catch(reject)
      }
    })
  }

  isDrawingToolsShown(){
    return true;//!this.isFlushNavigation();
  }

  private ensureSectionPathFill(sectionIndex:number, isConfirmationReq:boolean = true, isSilentPass:boolean = false){
    return new Promise((resolve, reject) => {
      
      const onReject = (msg:string) => {
        if (isSilentPass){
          resolve(null);
        }
        else{
          if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
              this.loginGuard.quickPopup(
              this.lang.tra(errorDetected.message),
              this.lang.tra("lbl_student_path_no_sel_confirm_bc"),      //BC French Pop-up update: OK button on path not selected warning.
            )
          } else {
              this.loginGuard.quickPopup(
              this.lang.tra(errorDetected.message),
            )
          }
          reject()
        }
      }

      const sectionPathOptions = this.testRunnerPathSections.get(sectionIndex);
      if (!sectionPathOptions){
        return resolve(null);
      }
      const targetSection = this.testRunnerSections[sectionIndex];
      if (!targetSection || targetSection.questions.length > 0){ // do not refill
        return resolve(null);
      }
      let sectionToInject:{
        sectionDef: ISectionDef,
        entryState:IEntryStateMcq,
        selectionIndex: number,
      }
      let errorDetected:{message: string};
      let MESSAGE_NO_SELECTION = 'lbl_student_path_no_sel';
      if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
        MESSAGE_NO_SELECTION = 'lbl_student_path_no_sel_bc';
      }
      const styleProfileMsg = this.styleProfile.getStyleProfile()[this.lang.c()].configuration?.testRunner?.pathSelectionPopup?.default?.pathNoSelectionPopup
      if (this.asmtFmrk && this.asmtFmrk.msgPathWarnOverride){
        MESSAGE_NO_SELECTION = this.asmtFmrk.msgPathWarnOverride;
      } else if (styleProfileMsg) {
        MESSAGE_NO_SELECTION = styleProfileMsg
      }

      sectionPathOptions.forEach(sectionDef => {
        if (errorDetected){ return; }
        // check
        const itemId = +sectionDef.conditionOnItem;
        const deciderItemState = this.getQuestionState(itemId);
        if (!deciderItemState){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const entryStates = this.getQuestionStateEntries(deciderItemState);
        if (!entryStates){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        // entryStates.forEach((entryState:IEntryStateMcq) => {
        const entryState:IEntryStateMcq = entryStates[0];
        if (!entryState || entryState.selections.length < 1){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const selection = entryState.selections[0]; // assuming its not multi select
        const selectionIndex = +selection.i;
        if (selectionIndex === +sectionDef.conditionOnOption){
          if (!deciderItemState.__meta){
            deciderItemState.__meta = {};
          }
          sectionToInject = {
            sectionDef,
            entryState,
            selectionIndex
          };
        }
      })

      if (!sectionToInject && !errorDetected){
        errorDetected = {message: MESSAGE_NO_SELECTION}
      }

      console.log('path selection', errorDetected, sectionToInject);

      if (errorDetected){
        onReject(errorDetected.message);
      }
      else if (sectionToInject){
        const sectionToFill = this.testRunnerSections[sectionIndex];
        const completeInjection = () => {
          Object.keys(sectionToInject.sectionDef).forEach(key => {
            if (key !== '__meta'){
              sectionToFill[key] = sectionToInject.sectionDef[key];
            }
          })
          sectionToInject.entryState.isPathFollowed = true;
          // forcesave entryState to reflect isPathFollowed instantly in the DB to mitigate different path selections for 2 tabs
          this._saveQuestion();
        }
        if (isConfirmationReq){

          const asmtFmrk:any = this.asmtFmrk || {};

          let lbl_student_path_cant_undo = 'lbl_student_path_cant_undo'

          if ((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT')))
            lbl_student_path_cant_undo = 'lbl_student_path_cant_undo_bc'
          
          let caption = this.lang.tra(asmtFmrk.msgPathCnfmOverride || lbl_student_path_cant_undo);
          let btnCancelCaption = this.lang.tra(asmtFmrk.msgPathCnfmCancelOverride || 'lbl_student_path_back');
          let btnProceedCaption = this.lang.tra(asmtFmrk.msgPathCnfmProceedOverride || 'lbl_student_path_proceed');
          const styleProfObj = this.styleProfile.getStyleProfile()[this.lang.c()].configuration?.testRunner?.pathSelectionPopup
          if (styleProfObj && styleProfObj["default"]) {
            if (styleProfObj["default"]["text"]) {
              caption = styleProfObj["default"]["text"]
            }
            if (styleProfObj["default"]["nextBtnText"]) {
              btnProceedCaption = styleProfObj["default"]["nextBtnText"]
            }
            if (styleProfObj["default"]["backBtnText"]) {
              btnCancelCaption = styleProfObj["default"]["backBtnText"]
            }
          }
          
          return this.loginGuard.confirmationReqActivate({
            caption,
            btnCancelCaption,
            btnProceedCaption,
            confirm: ()=> {
              completeInjection()
              resolve(null)
            },
          })
        }
        else if (isSilentPass){
          completeInjection()
          resolve(null)
        }
        else{
          return resolve(null)
        }
      }
      else{
        onReject('Unknown error in choosing path');
      }
    })
  }

  private getItemSelectionIndex(){

  }


  getNextModuleId(currentSectionIndex, log:boolean = false){
    const currentSection:IPanelModuleDef = this.getSection(currentSectionIndex);
    const currentModuleId = currentSection.moduleId;
    let nextRoute:{module:string, minPropC:number, maxPropC};
    let panelRouting = this.currentTestDesign.panelRouting[''+currentModuleId];
    let propC = this.computePercentageCorrect(currentSectionIndex);
    let targetLevel = 0;
    try {
      if (this.currentTestDesign.isPanelRoutingByNumCorrect){
        // console.log('propC', propC)
        panelRouting.forEach(route => {
          if ( (route.minPropC === undefined) || (propC >= route.minPropC) ){
            if ( (route.maxPropC === undefined) || (propC < route.maxPropC) ){
              nextRoute = route;
            }
          }
        })
      }
      else{
        // temporary...
        const gett = (a,b) => this.currentTestDesign.panelRouting[a][b];
        if ( currentSectionIndex == 0){
          if (propC < 0.4){ targetLevel = 0; }
          else if (propC < 0.8){ targetLevel = 1; }
          else { targetLevel = 2; }
          nextRoute = gett(1,targetLevel)
        }
        if (currentSectionIndex == 1){
          targetLevel = 0;
          nextRoute = gett(2,targetLevel)
        }
        if (currentSectionIndex == 2){
          if (propC < 0.4){ targetLevel = 0; }
          else if (propC < 0.8){ targetLevel = 1; }
          else { targetLevel = 2; }
          nextRoute = gett(5,targetLevel)
        }
      }
    }
    catch(e){}

    if (!nextRoute && panelRouting){
      console.warn('Number-Correct routing failed or is not enabled for this assessment panel, you will be routed to a random module option.');
      nextRoute = randArrEntry(panelRouting);
    }
    let targetModuleId
    if(nextRoute){
     targetModuleId = +nextRoute.module;
    }
    if(log && this.testAttemptId) {
      const logData = {
        uid: this.auth.getUid(),
        test_attempt_id: this.testAttemptId,
        current_section_index: currentSectionIndex,
        current_module_id: currentModuleId,
        target_module_id: targetModuleId,
        target_level: targetLevel,
        question_states: JSON.stringify(this.getCurrentQuestionStates())
      }
      this.auth.apiCreate(this.routes.STUDENT_STAGE_SUBMISSION, logData);
    }

    return targetModuleId;
  }

  loadNextModule(){
    const currentSectionIndex = this.testState.currentSectionIndex;
    const moduleId = this.getNextModuleId(this.testState.currentSectionIndex, true)
    this.loadQuestionsForModuleId(moduleId, currentSectionIndex+1);
    this.initSection();
  }
  loadQuestionsForModuleId(moduleId:number, targetSectionIndex:number){
    // console.log('loadQuestionsForModuleId', moduleId, this.currentTestDesign.panelModules)
    let nextPanelModule;
    this.currentTestDesign.panelModules.forEach(panelModule => {
      if (+panelModule.moduleId === moduleId){
        nextPanelModule = panelModule;
      }
    })
    if (!nextPanelModule){
      nextPanelModule = this.currentTestDesign.panelModules[1]; // gross temp
    }
    this.testState.currentModuleId = +nextPanelModule.moduleId;
    this.testRunnerSections[targetSectionIndex].questions = nextPanelModule.questions;
  }


  private computePercentageCorrect(sectionIndex:number) {
    let score = 0;
    let scoreMax = 0;
    const states = this.testState.questionStates;
    this.getCurrentQuestions().forEach(qId => {
      // console.log('states', states[qId]);
      const questionState = states[qId];
      if (questionState){
        let entryScore = 0;
        let entryScoreMax = 0;
        const entries = this.getQuestionStateEntries(questionState);
        entries.forEach(entryState => {
          // console.log('entry', questionState[entryId], questionState[entryId].score);
          if (entryState && entryState.score){
            entryScore += +entryState.score;
          }
          entryScoreMax ++;
        });
        if (entryScoreMax > 0){
          score += entryScore / entryScoreMax;
        }
      }
      scoreMax ++;
    });
    if (scoreMax > 0){
      return this.roundNumeric(score / scoreMax);
    }
    return 0;
  }
  
  private roundNumeric(num: number){
    return Math.round(100*num)/100; // fixed to 2 decimal places for now
  }

  getQuestionTestLabel(question) {
    const questionObj = this.questionSrcDb.get(question)
    console.log(questionObj)
    return questionObj["testLabel"];
  }

  isSecureProxy(){
    return false;
  }

  getQuestionRubricHelp(q:IContentElement) {
    if (q.elementType==ElementType.SOLUTION && q["isRubric"]) {
      return q;
    }
    let rubric;
    if (q && q["content"] && q["content"].length>0) {
      q["content"].forEach((q1)=>{
        let rub = this.getQuestionRubricHelp(q1)
        if (rub) {
          rubric = rub
        }
      })
      
    }
    return rubric
  }

  getQuestionRubric(question:number) {
    const quest:IQuestionConfig = this.getQuestionDef(question)
    let rubric:IContentElement = undefined
    console.log(quest)
    quest.content.forEach((q)=>{
      let rub = this.getQuestionRubricHelp(q)
      if (rub) {
        rubric = rub
      }
    })
    return rubric
  }

  goBackAndReviewYesNavigation(submitTest:boolean = false){
    const QUESTION_WORD = this.capitalizeWords('question', !this.isLang('en'));
    let nextBtnText = '';
    let popup = this.styleProfile.getSectionPopup(this.getCurrentSectionPopupSlug());
    
    if (submitTest && this.isFlushNavigation()) {
      nextBtnText = this.checkTag('BC_GRAD')? "alert_KK_SUBMIT_TEST_yes_bc_GRAD" : "alert_KK_SUBMIT_TEST_yes_bc";
      
      if (this.checkTag('BC_FSA_POPUPS') || this.checkTag('BC_NME10_POPUPS')){
        nextBtnText = 'alert_KK_SUBMIT_TEST_yes_bc_NME10'; // "Submit my assessment."
      }
    } else if (!submitTest && popup) {
      nextBtnText = popup.nextBtnText;
    } else {
      // backward compatibility

      nextBtnText = this.isFlushNavigation() ? "alert_KK_SUBMIT_SECTION_yes_bc" : "alert_KK_SUBMIT_TEST_yes";

      if (this.checkTag('BC_FSA_POPUPS') || this.checkTag('BC_NME10_POPUPS')){
        nextBtnText = 'alert_KK_SUBMIT_SECTION_yes_bc_FSA'; // "Advance to next section."
      }
    }
    return this.lang.tra(nextBtnText, undefined, { QUESTION_WORD });  
  }
  goBackAndReviewNoLabel() {
    const QUESTION_WORD = this.capitalizeWords('question', !this.isLang('en'));
    let backBtnText = '';
    let popup = this.styleProfile.getSectionPopup(this.getCurrentSectionPopupSlug());
    if (popup) {
      backBtnText = popup.backBtnText;
    } else {
      //backward compatibility
      backBtnText = this.isFlushNavigation() ? "alert_KK_SUBMIT_TEST_no_bc" : "alert_KK_SUBMIT_TEST_no";

      if (this.checkTag('BC_FSA_POPUPS') || this.checkTag('BC_NME10_POPUPS')){
        backBtnText = 'alert_KK_SUBMIT_TEST_no_bc_FSA'; // "Advance to next section."
      }
    }
    return this.lang.tra(backBtnText, undefined, { QUESTION_WORD });
    
  }

  private get currentSectionSettings() {
    let lastSectionIndex = this.asmtFmrk.partitions.length - 1;
    return (this.testState.currentSectionIndex > lastSectionIndex
              ? this.asmtFmrk.partitions[lastSectionIndex]
              : this.asmtFmrk.partitions[this.testState.currentSectionIndex]);
  }

  private isFrench(){
    return this.lang.c() === 'fr';
  }

  private capitalizeWords(str:string, isBlocked:boolean){
    if (isBlocked || !str){
      return str;
    }
    return str[0].toUpperCase()+str.substr(1, str.length-1)
  }

  zoomIn() {
    if (this.getZoomLevel() + this.zoomIncrement <=  this.maxZoomLevel) {
      this.zoom.update(this.getZoomLevel() + this.zoomIncrement);
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_IN", this.getZoomLevel());

    if(this.isLineReaderActive)  this.lineReaderWidth = DEFAULT_LINEREADER_WIDTH * (this.zoom.getZoom() / 1.5);
  }
  zoomOut() {
    if (this.getZoomLevel() - this.zoomIncrement >=  this.minZoomLevel) {
      this.zoom.update(this.getZoomLevel() - this.zoomIncrement)
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_OUT", this.getZoomLevel());

    if(this.isLineReaderActive)  this.lineReaderWidth = DEFAULT_LINEREADER_WIDTH * (this.zoom.getZoom() / 1.5);
  }

  getDocumentTooltip(){
    if (this.documentItems && this.documentItems.length === 1){
      return this.documentItems[0].caption;
    }
    const text = this.styleProfile.getStyleProfile()[this.lang.c()]?.configuration?.testRunner?.refDocs    
    if (text && text != '') {
      return text
    }
    return this.lang.tra('lbl_documents')
  }

  ghostAdded = false;
  getPixelsPerEM(id) {
    const el = document.getElementById(id);
    if (!el) return 0;
    const width = el.offsetWidth;
    const len = width/10;
    //document.body.removeChild(el);
    return len;
  }

  getLeftReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetLeft+'px'
  }

  getTopReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetTop+'px'
  }

  getLeftDrawingOverlayStyle() {
    const style:any={}
    const element = document.getElementById("readingPassageSplit")
    if (element) {
      style["left"] = element.offsetLeft
      style["top"] = element.offsetTop;
    }

    return element;
  }

  getNotepadSlug(){
    if (!this.isFlushNavigation()){
      return 'lbl_notepad'
    }
    else{
      return 'lbl_notepad_bc'
    }
  }

  sendIssueReport() {
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_REPORT_ISSUE,
      {
        test_session_id: this.testSessionId,
        question_id: this.getActiveQuestionId(),
        message: this.issueReportMessage.value,
      }
    )
    .then(e => {
      this.isShowingReport = false;
    });
  }
  reportIssue() {
    this.isShowingReport = true;
  }
  checkTimeLeft() {
    // this.activateModal( this.lang.tra('alert_TIME_LEFT'), ()=>{} );
    if (this.checkTime) {
      this.checkTime();
    }
    this.isShowingTime = true;
  }

  // Time-spent
  calculateTimeSpentQuestions =() => {
    if(typeof this.getQuestionTimeSpent !== 'function') return;
    this.timeSpentMap = new Map();
    this.getQuestionTimeSpent().then((timeSpentArr) => {
      timeSpentArr.map((q) => {
        let timeSpent = moment(q.end_time).valueOf() - moment(q.start_time).valueOf();
        if(this.timeSpentMap.has(q.item_id)){
          timeSpent += this.timeSpentMap.get(q.item_id)
        }
        this.timeSpentMap.set(q.item_id, timeSpent)
      })
    }).catch(e => console.log(e));
  }

  getTotalTimeSpentOnQuestion = (question_id) => {
    if(!this.timeSpentMap) return undefined;
    let totalTime = this.timeSpentMap.get(question_id);
    return totalTime ? this.formatTime(totalTime) : '00:00:00';
  }
  
  getElapsedTestTime(){
    const elpsedTime = this.getCurrentTime() - this.testStartTime;
    return this.formatTime(elpsedTime)
  }
  
  formatTime = (time: number) => {
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${this.leadingZero(hours)}:${this.leadingZero(minutes)}:${this.leadingZero(seconds)}`    
  }

  getCurrentTime() { return new Date().getTime() }

  getCurrentDateTime = () => moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');

  getInfoCaption(){
    const section = this.getCurrentSection();
    if (section){
      return section.infoCaption;
    }
  }

  openChat() {
    if (this.checkChat) {
      this.checkChat();
    }
    this.isShowingChat = true;
  }

  closePassage() {
    // console.log("closing Reading Passage")
    this.clearReadingSelection()
    this.hyperLinkService.linkRequestSecond.next({
      readerElementId: this.currentBookmark,
      readerId: this.currentReadSelection,
    });
    this.rightPageId = 0;
    this.onResize();
  }

  toggleReadingSelections() {
    // console.log("closing toggle", this.isShowingReadingSelections)
    if (this.isShowingReadingSelections) {
      this.isShowingReadingSelections = false;
      //this.closePassage();
    } else {
      this.isShowingReadingSelections = true;
    }
  }

  toggleTestNav() {
    this.isTestNavExpanded = !this.isTestNavExpanded;
  }
  toggleToolbar() {
    this.isToolbarExpanded = !this.isToolbarExpanded;
  }
  toggleLineReader() {
    this.isLineReaderActive = !this.isLineReaderActive;
    this.lineReaderWidth = DEFAULT_LINEREADER_WIDTH * (this.zoom.getZoom() / 1.5);
    if(!this.isLineReaderActive)  this.lineReaderWidth = DEFAULT_LINEREADER_WIDTH;
    this.logTool("LINE_READER", this.isLineReaderActive);
  }

  resizeLineReaderWidth(evt, resize){
    evt.stopPropagation()
    const { which: clickType } = evt;
    this.isResizeLineReaderWidth = clickType === 1 && resize ? true : false;
  }

  private _resizeLineReaderWidth(){
    const MAX_WIDTH_PX = 1500
    const MIN_WIDTH_PX = 300

    const { left: lineReaderLeft } = this.lineReader.nativeElement.getBoundingClientRect();

    let width = this.mousePosition.x > lineReaderLeft ? this.mousePosition.x - lineReaderLeft : 0;
    if(width > MAX_WIDTH_PX) width = MAX_WIDTH_PX
    if(width < MIN_WIDTH_PX) width = MIN_WIDTH_PX
    this.lineReaderWidth = width
  }

  toggleNotepad(){
    this.isNotepadEnabled = !this.isNotepadEnabled;
    this.logTool("NOTEPAD", this.isNotepadEnabled);
    if(!this.isNotepadEnabled){
      // save notes
      this._saveNotes();
    }
  }
  toggleHiContrast() {
    this.isHighContrast = !this.isHighContrast;
    this.logTool("HI_CONTRAST", this.isHighContrast);
    this.textToSpeech.hi_contrast_toggle();
  }

  toggleDocuments() {
    this.isShowDocuments = !this.isShowDocuments;
    this.logTool("SHOW_DOCS", this.isShowDocuments);
  }

  toggleFormula() {
    this.isShowFormulaSheet = !this.isShowFormulaSheet;
    this.logTool("SHOW_FORMULA_SHEET", this.isShowFormulaSheet);
  }

  toggleFormulas() {
    this.isFormulasToggledOn = !this.isFormulasToggledOn;
    if (this.isFormulasToggledOn) {
      this.slowScrollToTop();
    }
    this.logTool("SHOW_FORMULAS", this.isFormulasToggledOn);
  }

  toggleCalc() {
    this.isCalcToggledOn = !this.isCalcToggledOn;
    this.logTool("CALC", this.isCalcToggledOn);
    // if (this.isCalcToggledOn) {
    //   this.slowScrollToTop();
    // }
  }

  isToolBarSzieLarge = false;
  toggleSize() {
    this.isToolBarSzieLarge = !this.isToolBarSzieLarge;
    // this.logTool("SIZE", this.isToolBarSzieLarge);
  }

  goBackToMap = () => {
    this._saveQuestion().then(() => {
      sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
      this.backToMap.emit()
    })
  }

  goBackToMenu = () => {
    this.backToMenu.emit()
  }

  slowScrollToTop() {
    const el = this.questionDisplay.nativeElement;
    el.scrollIntoView({block: 'start'});
    setTimeout(() => {
      const elt = this.topBar.nativeElement;
      elt.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 100);
  }

  isThisOldFirefoxBrowser() {
    return isOldFirefoxBrowser()
  }

  isLang(langCode: string) {
    return (langCode === this.testLang);
  }

  isShowingCalc() {
    return this.isCalcToggledOn;
  }

  isLargeToolbar() {
    return this.isToolBarSzieLarge;
  }

  getSectionTitleSlug() {
    if (this.isFlushNavigation()) {
      if (this.lang.c() === 'fr'){
        return 'Partie'
      }
      else{
        return 'Part'
      }
    }
    if (!this.checkIsOsslt()) {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section';
      }
    } else {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section_osslt';
      }
    }
  }

  getSectionTimeRemainingSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'tr_stage_time_remaining';
      default: return 'tr_section_time_remaining';
    }
  }

  getAlertUnfilledWarnP2Slug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2_STAGE' ;
      default: return  this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2';
    }
  }

  getAlertKKSubmitSectionSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
      default: return 'alert_KK_SUBMIT_SECTION';
    }
  }

  getSections(){
    if (this.currentTestDesign && this.currentTestDesign.sections){
      return this.currentTestDesign.sections;
    }
    return []
  }


  getAlertKKSubmitTestSlug() {
    if(this.isFlushNavigation()) {
      return 'alert_KK_SUBMIT_EVALUATION'
    }
    // switch (this.testFormType){
    //   case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
    //   default: return 'alert_KK_SUBMIT_TEST';
    // }
    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      return 'alert_KK_SUBMIT_STAGE';
    }
    else{
      if (this.getSections().length > 1){
        return 'alert_KK_SUBMIT_TEST';
      }
      else{
        return 'alert_KK_SUBMIT_TEST_single_section'
      }
    }
  }

  logStudentAction(slug:string, info: any, slugPrefix? : string) {
    if(!this.auth.userIsStudent()) {
      return;
    }

    let prefix = slugPrefix || "";
    prefix += "_";
    this.auth.apiCreate( this.routes.LOG,
      {
        slug: `${prefix}${slug}`,
        data: {
          uid: this.auth.getUid(),
          session_id: this.testSessionId,
          state: {
            section_index: this.testState.currentSectionIndex,
            question_index: this.testState.currentQuestionIndex
          },
          info
        }
      }
    )
  }
  logTool(toolSlug: string, info: any) {
    this.logStudentAction(toolSlug, info, "STUDENT_ASMT_TOOL")
  }

  showReportOptions(option){
    let reportOptions = this.asmtFmrk.reportOptions;
    switch (option) {
      case ReportOptions.SCORE:
        return reportOptions ? !reportOptions.hideScore : true
      case ReportOptions.CHECKMARK:
        return reportOptions ? reportOptions.showCheckmark : false
      case ReportOptions.STUDENT_PEN:
        return reportOptions ? !reportOptions.hideStudentPen : true
      case ReportOptions.LEGEND:
        return reportOptions ? !reportOptions.hideLegend : true
      default:
        return;
    }
  }

  getProportionalQuestionScore(qId: number, round?: boolean) {
    let pScore = this.questionPScores.get(qId);
    if(pScore !== undefined) {
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    } 
    else {
      const qRes = this.questionStates[qId];
      pScore = 0;
      if (qRes){
        let entries = Object.keys(qRes).filter((key) => {
          return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
        });
        entries.forEach(entryId => {
          const entryState = qRes[entryId];
          // console.log(qId, entryId, pScore)
          pScore += entryState.score;
        })
      }
      this.questionPScores.set(qId, pScore);
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    }
  }

  getQuestionScoreDisplay(qId: number){
    let score = this.getProportionalQuestionScore(qId, true);
    return score;
  }

  getQuestionScore(qId: number) {
    const correct = this.questionScores.get(qId);
    if(correct === QuestionScore.CORRECT) {
      return this.getQuestionTotalScore(qId);
    } 
    else if(correct === QuestionScore.INCORRECT) {
      return 0;
    } 
    else {
      return 0;
    }

    //all or nothing scoring. Do we award part marks?
  }

  getQuestionTotalScore(qId: number) {
    const question = this.questionSrcDb.get(qId);
    let points;
    if (question){
      points = (<IQuestionConfig>question).points;
    }
    if(!points) {
      return 0;
    }

    const tokens = points.split(/\s/);
    if(!tokens || tokens.length === 0) {
      return 0;
    }

    return +tokens[0];
  }

  initFinalReportStats(){
    this.finalReportStats = {
      itemScore: {},
      numSRQuestions : 0,
      numCRQuestions : 0,
      numCorrectSRQuestions : 0,
      correctSRScore : 0,
      totalSRScore : 0,
      totalCRScore : 0
    }
  }

  scoreAllQuestions() {
    this.initFinalReportStats()
    const finalObj = this.finalReportStats
    const states = this.questionStates;
    return new Promise<void>((resolve, _) => {
      console.log("in promise")
      this.testRunnerSections.forEach(section => {
        if(section.disableScoring){
          return;
        }
        // console.log('section.questions', section.questions)
        section.questions.forEach(qId => {
          const question = <IQuestionConfig>this.questionSrcDb.get(qId);
          if(question.isReadingSelectionPage) {
            return;
          }
          const qRes = states[qId];
          const totalItemWeight = this.getQuestionTotalScore(qId);
          if(this.isManuallyScored(question)) {
            this.questionScores.set(qId, QuestionScore.UNMARKED);
            finalObj.numCRQuestions++;
            finalObj.totalCRScore += totalItemWeight;
          } 
          else {
            
            const correct = this.isQResAllCorrect(qRes);
            this.questionScores.set(qId, correct ? QuestionScore.CORRECT : QuestionScore.INCORRECT);
            const correctItemScore = this.getProportionalQuestionScore(qId, true);
            finalObj.correctSRScore += correctItemScore; // this.getQuestionScore(qId); 
            if(correct) {
              finalObj.numCorrectSRQuestions++;
            }
            finalObj.totalSRScore += this.getQuestionPoints(question.content);
            finalObj.numSRQuestions++;
            finalObj.itemScore[qId] =  { correctItemScore, totalItemWeight }
          }
        });
      });
      finalObj.totalSRScore = this.roundNumeric(finalObj.totalSRScore);
      console.log("Final Report Stats:",finalObj)
      let resolveFinalObj = Object.keys(finalObj.itemScore).length === finalObj.numSRQuestions
      /*if(resolveFinalObj)*/ resolve() 
    })
    
  }

  isHighContrastMode() {
    return this.isHighContrast
  }

  getFlagURL = () => {
    //if (!this.isHighContrastMode()) {
      return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/flag%20(coloured)/1607961301596/flag%20(coloured).svg"
    /*} else {
      return "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/flag%20(coloured)/1619201479179/flag%20(coloured).svg"
    }*/
  }

  getCorrectItemScore(qId: number, round:boolean = false){
    // const qId = this.getActiveQuestionId()
    const item = this.finalReportStats.hasOwnProperty('itemScore') ?  this.finalReportStats.itemScore[qId] : undefined
    if(item) {
      let score = round ? this.roundNumeric(item.correctItemScore) : item.correctItemScore
      if(this.lang.c() === 'fr') return score.toLocaleString('fr-FR')
      return score
    }
    return 0;
  }

  getQuestionPoints(elements: IContentElement[]){
    const entryElements = <Partial<IScoredResponse>[]> identifyQuestionResponseEntries(elements, [], true);
    let pointsTotal = 0;
    entryElements.forEach(entryElement => {
      pointsTotal += +getElementWeight(entryElement)
    });
    return pointsTotal;
  }

  private containsAutoScorableElement(elements: IContentElement[]) {
    if(!elements) {
      return false;
    }
    const entryElements = identifyQuestionResponseEntries(elements, [], true);
    let retValue = false
    if (entryElements && entryElements.length>0) {
      entryElements.forEach((element)=>{
        if (!element["isOptional"]) {
          retValue = true
        }
      })
    }
    return retValue
  }

  triggerPrintDialog() {
    window.print();
  }

  private isAutoScorableElement(element:any) : boolean {
    return checkElementIsEntry(element, true, 'type');
  } 


  private getQResPercentageCorrect(qRes) {
    if(!qRes) {
      return 0;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });

    if(!entries || entries.length === 0) {
      return 0;
    }    

    let numCorrect = 0;
    let totalPossible = 0;
    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(eRes.isCorrect) {
        numCorrect++;
      } 
      if(eRes.isCorrect !== undefined) {
        totalPossible++;
      }
    }

    if(totalPossible === 0) {
      return 0;
    }
    return numCorrect/totalPossible;
  }


  private isQResAllCorrect(qRes) {
    if(!qRes) {
      return false;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });
    if(!entries || entries.length === 0) {
      return false;
    }    
    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(!eRes.isCorrect && eRes.isCorrect !== undefined) {
        return false;
      }
    }
    return true;
  }

  isManuallyScored(question) {
    return !this.containsAutoScorableElement(question.content);
  }

  showResultsDetailPages(){
    return !(this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled)
  }  

  isPageEnabledInResultsMode(mode){
    if(mode.slug === PageMode.REVIEW_QUESTIONS) return false;
    return mode.caption && (this.showResultsDetailPages() || !mode.isDetail);
  }

  exportResults() {
    this.isPrintMode = true;
  }

  exitExportResults() {
    this.isPrintMode = false;
  }

  isNotTestTaker() {
    return this.isTeacher()
    // try {
    //   return this.auth.user() && this.auth.user().value.accountType !== AccountType.TEST_TAKER
    // }
    // catch(e){
    //   return true;
    // }
  }
  
  getCustomResultsText() {
    return this.asmtFmrk.msgResultsPage;
    // const def = 'tr_results_intro_text_2'
    // const msg = this.asmtFmrk.msgResultsPage;
    
    // return !msg ? def : (msg || def);
  }

  getCustomResultsPage(){
    
    let pageText = ''
    let titleText = `<div class="page-title"><markdown>${this.asmtFmrk.customResultPageTitle || this.lang.tra('bc-results-page-text')}</markdown></div>`
    let sentences = this.asmtFmrk.customResultPage.split('\n')
    
    sentences.forEach(sentence => {
      let text = sentence.trim()
      if(text) { pageText += `<div><markdown>${text}</markdown></div>` }
    })

    return titleText + pageText
  }

  getResultTitleSlug(){
    return this.asmtFmrk.isOrale ? 'tr_results_page_orale' : 'tr_results_page'
  }

  getSubmissionText() {
    const def = this.btnReviewSubmit; //'btn_review_submit';
    const section = this.getCurrentSection();
    return !section ? def : (section.submissionText || def);
  }
  getNotepadText() {
    const section = this.getCurrentSection();
    if (section && section.notepadText){
      return section.notepadText
    }
    return null
  }
  onTextAreaInputChange(input){
    // No element entryId for notepad, but notepad remains across testrunner.
    this.studentSecurity.updateChar(DEFAULT_NOTEPAD_ID, input);
  }

  isTeacher(){
    if (this.frameWorkTagsRef.get('IS_TEACHER_VIEW')){
      return true;
    }
    return false
  }

  writtenOn:string;
  getWritingDate(){
    if (!this.writtenOn){
      this.writtenOn = moment().format( this.lang.tra('datefmt_day_month_year_dow'));
    }
    return this.writtenOn;
  }

  getAssessmentName(toLower:boolean = false){
    if (this.asmtFmrk){
      if(toLower && this.asmtFmrk.assessmentName) return this.asmtFmrk.assessmentName.charAt(0).toLowerCase() + this.asmtFmrk.assessmentName.slice(1);
      return this.asmtFmrk.assessmentName || '';
    }
    return ''
  }

  getTestTakerID(){
    return this.testTakerPEN || this.testTakerName || "____________";
  }

  getTestTakerName = () => {
    if(this.assessmentInfo && this.assessmentInfo.assessmentType == AssessmentType.GRAD){      
      // For GRAD assessments we want ` last_name – learningType (Num/Lit) grad`
      return `${this.testTakerLastName || this.testTakerName} –  ${this.getAssessmentName()}`; 
    } 
    return this.testTakerName;
  }

  downloadRubric() {
    const wasActive = this.dataGuard.isActive();
    if(wasActive) {
      this.dataGuard.deactivate();
    }
    this.rubricLinkRef.nativeElement.click();
    if(wasActive) {
      this.dataGuard.activate();
    }
  }

  getCalculatorType() {
    if (this.frameWorkTags) {
      for(const slug of this.frameWorkTags){
        if(slug.slug in CALC) return CALC[slug.slug];
      } 
    }
    return "";
  }

  editItem() {
    this.onEditItem.emit(this.getActiveQuestionId());
  }

  isAllowedSubtitles() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_SUBS')) {
      return true;
    }
    return false;
  }

  isAllowedTranscripts() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_TRANSCRIPTS')) {
      return true;
    }
    return false;
  }

  isAllowedAudioPlaybackSpeed() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_AUDIO_PLAYBACK_SPEED') && (this.isAccomm || this.isTTS)) {
      return true;
    }
    return false;
  }

  isAllowedVideoPlaybackSpeed() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_VIDEO_PLAYBACK_SPEED') && (this.isAccomm || this.isTTS)) {
      return true;
    }
    return false;
  }

  manualSaveQuestion() {
    if (this.getActiveQuestionContent().enableManualSaveButton){
      this._saveQuestion().then(() => {
        alert("Question Saved!")
      });
    }
  }

  getZoomInTooltip(){
    if((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
      return this.lang.tra('btn_zoom_in_FSA');
    }
    return this.lang.tra('btn_zoom_in');
  }

  getZoomOutTooltip(){
    if((this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT'))){
      return this.lang.tra('btn_zoom_out_FSA');
    }
    return this.lang.tra('btn_zoom_out');
  }

  getKeyboardIconColor(){
    if(this.isHighContrastMode()){
      return 'black'
    }
    return '#fcbc14'
  }

  showScrollIndication() {
    if (!this.scrollIndicator) return;
    // check when the question has been loaded and selectively display indicator 
    let questionMaxHeight = this.scrollIndicator.nativeElement.scrollHeight
    let questionDefaultHeight = this.scrollIndicator.nativeElement.clientHeight
    let scrollPosition = this.scrollIndicator.nativeElement.scrollTop

    if (scrollPosition === 0 && questionMaxHeight > questionDefaultHeight) {
      this.scrollIndicationStatus =  true
    } else {
      this.scrollIndicationStatus =  false
    }
  }
  
  isAllowToobarSizeAdjustment(){
    return this.styleProfile.getStyleProfile()[this.lang.c()]?.configuration?.toolbar?.allowToolbarSizeAdjustment    
  }

  getToolbarSizeButtonTooltip(){
    if (this.isToolBarSzieLarge){
      return this.lang.tra('btn_normal_toolbar')
    }
    return this.lang.tra('btn_large_toolbar')
  }

  isTTStoolEnabled(){
    return this.isText2SpeechEnabled && this.questionRunner?.getQuestionAudioUrl()
  }

  ttsTooltip(){
    if (!this.isTTStoolEnabled()){
      return this.lang.tra('btn_listen_no_voice_over_available');
    }
    return this.lang.tra('btn_listen');
  }

  isFireFox(){
    let userAgent = navigator.userAgent;
    if(userAgent.match(/firefox|fxios/i))
      return true;
    return false;
  }

  getToolbarBottomPadding(){
    const infoButtonElement = Array.from(document.getElementsByClassName('info-button-toolbar'))[0];
    if (infoButtonElement) return infoButtonElement.clientHeight;
    return 0;
  }

  onPaste() {
    this.studentSecurity.pasteHandler();
  }

  // For the new (alternative) grad result page
  getResultSummaryTitleSlug(){
    if (this.checkTag('FR_RESULTS')) return 'tr_results_summary_title_fr_sample';
    return 'tr_results_summary_title';
  }

  getResultSummaryText1Slug(){
    if (this.checkTag('FR_RESULTS')) return 'tr_results_summary_text_1_fr_sample';
    return 'tr_results_summary_text_1';
  }

  getResultSummaryText2Slug(){
    if (this.checkTag('FR_RESULTS')) return 'tr_results_summary_text_2_fr_sample';
    return 'tr_results_summary_text_2';
  }
}
