import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { AssessmentType, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcAccountsService, DistrictDetail, SchoolDetail } from "../../bc-accounts/bc-accounts.service";
import { calculateRatio, parseWindowCode } from '../../utils';

@Component({
  selector: 'fsa-proficiency-level-report',
  templateUrl: './fsa-proficiency-level-report.component.html',
  styleUrls: ['./fsa-proficiency-level-report.component.scss']
})
export class FsaProficiencyLevelReportComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private bcAccounts: BcAccountsService,
  ) { 

  }

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() showHeader: boolean = false;

  public allowToAccess: boolean = false;
  public isLoading: boolean = false;
  numSchoolsToLoad: number = 0;
  numSchoolsLoaded: number = 0;
  public proficiencyLevels = {
    1: "Emerging",
    2: "On Track",
    3: "Extending",
  };

  public chartLabels = ["Emerging","On Track","Extending"];
  public literacyGrade4Data = [];
  public literacyGrade7Data = [];
  public numeracyGrade4Data = [];
  public numeracyGrade7Data = [];
  public isScaledResultReady = false;

  public data = [
    {
      grade: "4",
      subject: "literacy",
      registrations: 0,
      numbers: [0,0,0],
    },
    {
      grade: "4",
      subject: "numeracy",
      registrations: 0,
      numbers: [0,0,0],
    },
    {
      grade: "7",
      subject: "literacy",
      registrations: 0,
      numbers: [0,0,0],
    },
    {
      grade: "7",
      subject: "numeracy",
      registrations: 0,
      numbers: [0,0,0],
    }
  ];


  ngOnChanges(changes: SimpleChanges) {
    if (this.auth.isSchoolAdmin()) {
      if (this.schoolDetail) {
        this.fetchData();
      }
    } else {
      this.fetchData();
    }
  }

  ngOnInit(): void {
  }

  private resetData() {
    for(let row of this.data) {
      row.registrations = 0;
      row.numbers = [0,0,0];
    }
  }

  public async fetchData() {
    if (!this.testWindow) return;
    if (!(this.districtDetail || this.schoolDetail)) return;
  
    this.isLoading = true;
    this.resetData();
  
    try {
      let schools = [];
  
      // If we have districtDetail, fetch all schools in the district
      if (this.districtDetail && !this.schoolDetail) {
        schools = await this.bcAccounts.fetchSchools({ districtGroupId: this.districtDetail.groupId, assessmentType: AssessmentType.FSA });
      } else if (this.schoolDetail) {
        // If we have schoolDetail, just use that one school
        schools = [this.schoolDetail];
      }

      this.numSchoolsToLoad = schools.length;
      this.numSchoolsLoaded = 0;
      for (const school of schools) {
        for (const grade of ['4', '7']) {
          const res = await this.auth.apiFind(this.routes.BCED_PROFICIENCY_LEVEL, {
            query: {
              testWindowId: this.testWindow.id, 
              grade,
              schoolGroupId: school.groupId
            }
          });

          // Recombine results for literacy and numeracy across all schools
          for (const subject of ['literacy', 'numeracy']) {
              const row = this.data.find(item => item.grade === grade && item.subject === subject);
              
              if (row) {
                row.registrations = (row.registrations || 0) + res.registrations;
                this.isScaledResultReady = this.isScaledResultReady || res.isScaledReady;
                
                // Ensure sortedNumbers is an array of exactly 3 elements for the three proficiency levels
                const sortedNumbers = [0, 0, 0];
                res[subject].forEach(item => {
                  sortedNumbers[item.proficiency_level - 1] = item.count;
                });

                for(let i = 0; i < row.numbers.length; i++) {
                  row.numbers[i] += sortedNumbers[i];
                }
              }
          }
        }
        this.numSchoolsLoaded++;
      }
  
      this.allowToAccess = true;
    } catch (error) {
      console.log({ error });
      if (error instanceof Error) {
        console.log(error.message);
        if (error.message === 'test_window_not_allowed') {
          this.allowToAccess = false;
        }
      }
    }
  
    console.log(this.data);
    this.isLoading = false;
  }
  

  public capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  public getSum(value: number[]): number {
    return value.reduce((prev, curr) => prev + curr, 0);
  }

  public getPercentageDisplay(n: number, d: number): string {
    const ratio = calculateRatio(n, d) * 100;
    
    return ratio.toFixed(2);
  }

  public printReport() {
    window.print();
  }

  public getAdministrationYear():string {
    if (!this.testWindow.window_code) return "";

    const { year, month } = parseWindowCode(this.testWindow.window_code);

    return `${year}-${+year + 1} FSA`;
  }
}
